import React, { useState } from "react";
import "../Styles/Settings.scss";
import SettingPost from "../Components/SettingPost.tsx";
import SettingComission from "../Components/SettingComission.tsx";
import SettingProgramBrief from "../Components/SettingProgramBrief.tsx";


const Settings = () => {
    const [activeTab, setActiveTab] = useState('posts');

    return (
        <div className="settting_container">
            <div className="setting_subnavbar_efes">
                <div style={{ marginLeft: "auto", marginRight: "20px", display: "flex" }}>
                    <div
                        className={`element_subnavbar_efes ${activeTab === 'posts' ? 'active' : ''}`}
                        onClick={() => setActiveTab('posts')}
                    >
                        Должности
                    </div>
                    <div
                        className={`element_subnavbar_efes ${activeTab === 'commissions' ? 'active' : ''}`}
                        onClick={() => setActiveTab('commissions')}
                    >
                        Комиссии
                    </div>
                    <div
                        className={`element_subnavbar_efes ${activeTab === 'programs' ? 'active' : ''}`}
                        onClick={() => setActiveTab('programs')}
                    >
                        Программы инструктажей
                    </div>
                </div>
            </div>
            <div className="setting_content_efes">
                {activeTab === 'posts' && <SettingPost />}
                {activeTab === 'commissions' && <SettingComission />}
                {activeTab === 'programs' && <SettingProgramBrief />}
            </div>
        </div>
    );
};

export default Settings;