import React, { useState } from "react";
import axiosInstance from "../axiosConfigEfes";
import { useNavigate } from 'react-router-dom';
import '../../MarsPets/Styles/login.scss';

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
  
    const handleLogin = async (e) => {
      e.preventDefault();
  
      try {
        const response = await axiosInstance.post("/efes_login", {
          username,
          password,
        });
  
        if (response.status === 200 && response.data && response.data.token) {
  
          // Сохранение данных авторизации
          localStorage.setItem("token_efes", response.data.token);
          localStorage.setItem("role_efes", response.data.role);
          localStorage.setItem("fio_efes", response.data.fio);
  
          navigate("/efes/", { replace: true });
        } else {
          setError("Неверный ответ от сервера");
        }
      } catch (err) {
        setError("Неверное имя пользователя или пароль");
      }
    };
  
    return (
      <div className="login_start_container">
        <a href="/" className="button-34">🠔 К выбору объекта</a>
        <div className="login-page">
        <img src="https://companieslogo.com/img/orig/AEFES.IS-2d1e0fd5.png?t=1720244490" alt="" width="200px" height="150px" style={{objectFit:"contain"}}/>
          <div className="form">
            <form className="login-form" onSubmit={handleLogin}>
              <input
                type="text"
                placeholder="Логин"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button className="button-65" type="submit">Войти</button>
              {error && <p className="error" style={{color: "red", marginBottom: "0px"}}>{error}</p>}
            </form>
          </div>
        </div>
      </div>
    );
}

export default Login;
