import React, { useState } from 'react';
import { useGetNormalSizEfesQuery } from '../Global/NormalSizEfesSlice.ts';
import { useGetPostQuery } from '../Global/PostEfesSlice.ts';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import Notification from "../Components/Notification.tsx";
import axiosInstance from '../axiosConfigEfes.js';
import '../Styles/NormalSiz.scss';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const NormalSiz = () => {
    const { data: normal, error, isLoading, refetch } = useGetNormalSizEfesQuery();
    const { data: postOptions } = useGetPostQuery();

    const [name, setName] = useState("");
    const [model, setModel] = useState("");
    const [col, setCol] = useState("");
    const [sum, setSum] = useState<number | null>(null);
    const [type, setType] = useState("");
    const [main, setMain] = useState(false);
    const [post, setPost] = useState("");

    const [showModalAdd, setShowModalAdd] = useState(false);
    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    const confirmAddNormalSiz = async () => {
        if (!name || !model || !col || !type || !post || !sum) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Необходимо заполнить все поля",
            subtitle2: <div></div>,
            isVisible: true,
          });
          return;
        }

        if (sum <= 0) {
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: "Необходимо чтобы общее количество было больше 0",
              subtitle2: <div></div>,
              isVisible: true,
            });
            return;
        }
    
        const data = {
            name: name,
            model: model,
            col: col,
            sum: sum,
            type: type,
            main: main,
            postid: post,
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };

        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });

            await axiosInstance.post(`/efes/add_new_normal`, data);
            
            setShowModalAdd(false);
            refetch();
      
            setNotification({
              status: "success",
              title: "Успешно",
              subtitle: "Норма добавлена",
              subtitle2: <div></div>,
              isVisible: true,
            });
      
        } catch (error: any) {
            const errorMessage =
              error.response?.data || "Ошибка при добавлении нормы"; 
        
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: errorMessage,
              subtitle2: <div></div>,
              isVisible: true,
            });
          }
    }

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Название",
            width: 150,
            valueGetter: (value, row) => {
                if (row.name !== ""){
                    return row.name;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "model",
            headerName: "Описание",
            width: 300,
            valueGetter: (value, row) => {
                if (row.model !== ""){
                    return row.model;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "col",
            headerName: "Кол. на чел.",
            width: 110,
            valueGetter: (value, row) => {
                if (row.col !== ""){
                    return row.col;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "type",
            headerName: "Сезон",
            width: 120,
            valueGetter: (value, row) => {
                if (row.type !== ""){
                    return row.type;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "post",
            headerName: "Должность",
            width: 120,
            valueGetter: (value, row) => {
                if (row.post !== ""){
                    return row.post.namepost;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "main",
            headerName: "Основная",
            width: 80,
            valueGetter: (value, row) => {
                if (row.main === true){
                    return "✅";
                } else {
                    return "❌";
                }
            },
        },
        {
            field: "sum",
            headerName: "Остаток",
            width: 100,
            valueGetter: (value, row) => {
                if (row.sum !== ""){
                    return row.sum;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "button",
            headerName: "",
            width: 50,
            renderCell: (params) => (
              <div>
                <IconButton onClick={() => confirmDelete(params.row.id)}>
                    <DeleteIcon />
                </IconButton>
              </div>
            ),
        },
    ];

    const confirmDelete = async (id) => {
        const confirmLogout = window.confirm("Точно хотите удалить норму?");
        if (!confirmLogout) return;

        const data1 = {
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };
    
        try {
    
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
            await axiosInstance.delete(`/efes/delete_normal/${id}`, { data: data1 });

            refetch();
    
            setNotification({
                status: "success",
                title: "Успешно",
                subtitle: "Норма удалена",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
        } catch (error) {
            setNotification({
                status: "error",
                title: "Ошибка",
                subtitle: "Ошибка при удалении нормы",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    if (isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
      ;
        
      if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
          <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
          <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
      </div>
      ;

    return (
        <div className="active_employee_efes">
            <div className="container_normal_siz_employee_efes">
                <Button
                    variant="contained"
                    onClick={() => setShowModalAdd(true)}
                    style={{ marginTop: "20px" }}
                    >
                    Добавить норму
                </Button>
                <DataGrid
                    sx={{
                        marginTop: 2,
                        '& .MuiDataGrid-cell': {
                          display: 'flex',
                          justifyContent: 'center', 
                          alignItems: 'center',
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                        },
                        '& .MuiDataGrid-columnHeader': {
                          textAlign: 'center',
                        },
                    }}
                    rows={normal ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    getRowHeight={() => 'auto'}
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>
            {/* Модальное окно для добавления должности */}
            {showModalAdd && (
                <div className="modal_add_normal_siz_employee_efes">
                    <div className="modal_cont_add_normal_siz_employee_efes">
                        <div className="title_employee_efes">Добавление новой нормы</div>

                        <div style={{marginBottom: "15px"}}>
                            <label htmlFor="name">Название</label>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{width: "294px"}}
                            ></input>
                        </div>

                        <label htmlFor="desc">Описание</label>
                        <div style={{ fontSize: "13px", marginTop: "3px", marginBottom: "3px", color: "#4c4c4c"}}>Здесь необходимо указать модель, марку, артикул, класс защиты СИЗ, дерматологических СИЗ</div>
                        <textarea
                            name="desc"
                            placeholder="Описание"
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                            rows={4}
                            style={{marginBottom: "15px"}}
                        />

                        <div>
                            <label htmlFor="desc">Кол-во на одного человека</label>
                            <input
                                type="text"
                                name="desc"
                                value={col}
                                onChange={(e) => setCol(e.target.value)}
                                style={{width: "147px"}}
                            ></input>
                        </div>

                        <div style={{ fontSize: "13px", marginTop: "3px", marginBottom: "18px", color: "#4c4c4c"}}>Здесь необходимо указать количество с единицой измерения, напрмер, 1 шт. или 12 пар.</div>

                        <div>
                            <label
                                htmlFor="typeEmployment-dropdown"
                            >
                                Сезон:
                            </label>
                            <select
                                id="typeEmployment-dropdown"
                                value={type}
                                style={{ width: "330px", marginBottom: "15px", height: "22px"}}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value="" disabled>
                                    Выберите сезон
                                </option>
                                <option value="Зима" key="Зима" >
                                    Зима
                                </option>
                                <option value="Лето" key="Лето">
                                    Лето
                                </option>
                            </select>
                        </div>

                        <label style={{ marginBottom: "15px" }}>
                            <input
                                type="checkbox"
                                checked={main} 
                                onChange={(e) => setMain(e.target.checked)} 
                            />
                            {main ? 'Основной' : 'Не основной'}
                        </label>

                        <div>
                            <label htmlFor="post-dropdown" style={{ marginBottom: "10px" }}>
                                Должность:
                            </label>
                            <select
                                id="post-dropdown"
                                value={post}
                                style={{ width: "286px", marginBottom: "15px", height: "22px"}}
                                onChange={(e) => setPost(e.target.value)}
                            >
                                <option value="" disabled>
                                Выберите должность
                                </option>
                                {postOptions?.map((option: any) => (
                                <option key={option.id} value={option.id}>
                                    {option.namepost}
                                </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label htmlFor="sum">Общее количество</label>
                            <input
                                type="number"
                                name="sum"
                                value={sum ?? ""}
                                onChange={(e) => setSum(e.target.value === "" ? null  : Number(e.target.value))}
                                style={{width: "215px", marginBottom: "15px"}}
                            ></input>
                        </div>

                        <div className="modal_act_fir">
                            <button onClick={confirmAddNormalSiz}>Добавить</button>
                            <button onClick={() => setShowModalAdd(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}
            {notification.isVisible && (
                <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                onClose={hideNotification}
                isVisible={notification.isVisible}
                subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default NormalSiz;