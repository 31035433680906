import React, { useCallback, useEffect, useState } from "react";
import "../Styles/tableArea.scss";
import axios2 from "../../axiosConfig.js";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import Button from "@mui/material/Button";
import Select, { SingleValue } from "react-select";
import Notification from "../Components/notification.tsx";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../Global/store.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { clearEmployee } from "../Global/employeeSlice.ts";
import axiosInstance from "../../axiosConfig.js";

interface EmployeeOption {
  value: number;
  label: string;
}

interface AreaOption {
  value: number;
  label: string;
}

interface Employee {
  value: number;
  id: number;
  fio: string;
  datelmk: string;
  dateflg: string;
  dateadsm: string;
}

interface Area {
  id: number;
  namearea: string;
}

const TableArea = () => {
  const employeeNext = useSelector((state: AppState) => state.employee?.employee);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDateEnd, setSelectedDateEnd] = useState("");
  const [dataEmployee, setDataEmployee] = useState<Employee[]>([]);
  const [dataMentor, setDataMentor] = useState<Employee[]>([]);
  const [mentors, setMentors] = useState<Employee[]>([]);
  const [dataForemen, setDataForemen] = useState<Employee[]>([]);
  const [numSmen, setSelectedSmen] = useState("");
  const [dataArea, setArea] = useState<Area[]>([]);
  const [main, setSelectedMain] = useState(false);
  const [selectedAction, setSelectedAction] = useState({});
  const [nextEmployeeModalBtn, setNextEmployeeModalBtn] = useState(false);
  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedEmployee, setSelectedEmployee] =
    useState<SingleValue<EmployeeOption>>(null);
  const [selectedMentor, setSelectedMentor] =
    useState<SingleValue<EmployeeOption>>(null);
  const [selectedArea, setSelectedArea] =
    useState<SingleValue<AreaOption>>(null);
  const [selectedForemen, setSelectedForemen] =
    useState<SingleValue<EmployeeOption>>(null);

  const employeeOptions: EmployeeOption[] = dataEmployee.map((employee) => ({
    value: employee.id,
    label: employee.fio,
  }));

  const mentorOptions: EmployeeOption[] = mentors.map((employee) => ({
    value: employee.id,
    label: employee.fio,
  }));

  const areaOptions: AreaOption[] = dataArea.map((area) => ({
    value: area.id,
    label: area.namearea,
  }));

  const foremenOptions: EmployeeOption[] = dataForemen.map((employee) => ({
    value: employee.id,
    label: employee.fio,
  }));

  const handleEmployeeChange = (
    selectedOption: SingleValue<EmployeeOption> | null
  ) => {
    setSelectedEmployee(selectedOption);
    if (selectedOption) {
      const filteredMentors = dataMentor.filter(
        (mentor) => mentor.id !== selectedOption.value
      );
      setMentors(filteredMentors);
    } else {
      setMentors(dataMentor);
    }
  };

  const fetchData = useCallback(() => {
    axios2
      .get("/trainings")
      .then((response) => {
        const filteredData = response.data.filter(
          (row) => row.employee.status === true
        );

        const formattedData = filteredData.map((row) => ({
          ...row,
          date: formatDate(row.date),
          dateend: formatDate(row.dateend),
        }));
        setData(formattedData);
      })
      .catch((error) => {
        console.error("Ошибка при выполнении GET-запроса для размеров", error);
      });

    axios2
      .get("/employees")
      .then((response) => {
        const filteredData = response.data.filter(
          (row) => row.mainPost && row.status === true
        );
        const filteredDataMentor = response.data.filter(
          (row) => row.mainPost && row.status === true
        );
        const filteredDataForemen = response.data.filter(
          (row) => row.mainPost && row.status === true && row.mainPost.id === 1
        );
        setDataEmployee(filteredData);
        setDataMentor(filteredDataMentor);
        setDataForemen(filteredDataForemen);
      })
      .catch((error) => {
        console.error(
          "Ошибка при выполнении GET-запроса для сотрудников",
          error
        );
      });

    axios2
      .get("/areas")
      .then((response) => {
        const areas = response.data;
        setArea(areas);
      })
      .catch((error) => {
        console.error("Ошибка при выполнении GET-запроса для участков", error);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (dataMentor.length > 0) {
      if (selectedEmployee) {
        const filteredMentors = dataMentor.filter(
          (mentor) => mentor.value !== selectedEmployee.value
        );
        setMentors(filteredMentors);
      } else {
        setMentors(dataMentor);
      }
    }
  }, [dataMentor, selectedEmployee]);

  useEffect(() => {
    if (location.state?.showModal) {
      setNextEmployeeModalBtn(true);
      setShowModal(true);

      if (employeeNext) {
        const nextEmpl: EmployeeOption = {
          value: employeeNext.id,
          label: employeeNext.fio,
        };
        setSelectedEmployee(nextEmpl);
        handleEmployeeChange(nextEmpl);
      }
    }
  }, [location.state, employeeNext]);

  const handlePrintDoc = async (documentType, employeeid) => {
    try {
      const response = await axiosInstance.get(
        `/documents/${documentType}/${employeeid}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      link.click();
      window.URL.revokeObjectURL(url);
    
    } catch (error) {
      console.error("Ошибка при получении PDF:", error);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleAction = (id, employeeid) => {
    switch (selectedAction) {
      case "changeStatus":
        handleMain(id);
        break;
      case "printPish":
        handlePrintDoc("pish", id);
        break;
      case "printSiz":
        handlePrintDoc("siz", employeeid);
        break;
      case "printStaz":
        handlePrintDoc("staz", id);
        break;
      case "printOtitb":
        handlePrintDoc("otitb", employeeid);
        break;
      case "printLna":
        handlePrintDoc("lna", employeeid);
        break;
      case "printYdelectro":
        handlePrintDoc("ydelectro", employeeid);
        break;
      case "printProtelectro":
        handlePrintDoc("protelectro", employeeid);
        break;
      default:
        break;
    }
  };

  const handleMain = async (id) => {
    try {
      const response = await axios2.get(`/trainings/${id}`);
      const training = response.data;

      if (training.main) {
        setNotification({
          status: "error",
          title: "Ошибка",
          subtitle: "Этот участок является единственным основным участком",
          isVisible: true,
          subtitle2: <div>Сначала сделайте основным другой участок у этого сотрудника</div>,
        });
        return;
      }

      training.main = !training.main;
      await axios2.put(`/trainings/${id}`, training);
      fetchData();
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Статус изменен",
        isVisible: true,
        subtitle2: <div></div>,
      });
    } catch (error) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Ошибка при изменении статуса",
        isVisible: true,
        subtitle2: <div></div>,
      });
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const confirm = async () => {
    const formattedStart = new Date(selectedDate);
    const formattedEnd = new Date(selectedDateEnd);

    if (formattedEnd < formattedStart) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Дата окончания не может быть раньше даты начала",
        isVisible: true,
        subtitle2: <div></div>,
      });
      return;
    }
    try {
      const formattedDate = formattedStart.toISOString();
      const formattedDate2 = formattedEnd.toISOString();

      const trainData = {
        employeeid: selectedEmployee?.value,
        date: formattedDate,
        dateend: formattedDate2,
        mentorid: selectedMentor?.value,
        foremenid: selectedForemen?.value,
        areaid: selectedArea?.value,
        numsmen: numSmen,
        main: main,
      };

      const responseTrain = await axios2.post(`/trainings`, trainData);
      const dataNewTrain = responseTrain.data;
      setShowModal(false);
      fetchData();
      if (nextEmployeeModalBtn) {
        setNotification({
          status: "success",
          title: "Успешно",
          subtitle: "",
          subtitle2: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>Проверьте, все ли документы напечатаны:</div>
              <div>-пищевка</div>
              <div>-карточка СИЗ</div>
              <div>-распоряжение о стажировке</div>
              <div>-протокол ОТиТБ</div>
              <div>-личная карточка</div>
              {/* <div>-удостоверение по ЭБ</div> */}
              <div>-протокол проверки знаний в электроустановках</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "15px",
                }}
              >
                <p>Нажмите, чтобы напечатать: </p>
                <Button
                  variant="contained"
                  onClick={() => handlePrintDoc("pish", dataNewTrain.id)}
                  style={{ marginTop: "10px" }}
                >
                  пищевка
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handlePrintDoc("siz", employeeNext?.id)}
                  style={{ marginTop: "10px" }}
                >
                  карточка СИЗ
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handlePrintDoc("staz", dataNewTrain.id)}
                  style={{ marginTop: "10px" }}
                >
                  распоряжение о стажировке
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handlePrintDoc("otitb", employeeNext?.id)}
                  style={{ marginTop: "10px" }}
                >
                  протокол ОТиТБ
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handlePrintDoc("lna", employeeNext?.id)}
                  style={{ marginTop: "10px" }}
                >
                  личная карточка
                </Button>
                {/* <Button
                  variant="contained"
                  onClick={() => handlePrintDoc("ydelectro", employeeNext?.id)}
                  style={{ marginTop: "10px" }}
                >
                  удостоверение по ЭБ
                </Button> */}
                <Button
                  variant="contained"
                  onClick={() =>
                    handlePrintDoc("protelectro", employeeNext?.id)
                  }
                  style={{ marginTop: "10px" }}
                >
                  протокол проверки знаний в электроустановках
                </Button>
              </div>
            </div>
          ),
          isVisible: true,
        });
        dispatch(clearEmployee());
        navigate(location.pathname, { state: { showModal: false } });
      } else {
        setNotification({
          status: "success",
          title: "Успешно",
          subtitle: "Обучение добавлено",
          isVisible: true,
          subtitle2: <div></div>,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setNotification({
          status: "error",
          title: "Ошибка",
          subtitle: "Ошибка при добавлении обучения",
          isVisible: true,
          subtitle2: (
            <div>С этого сотрудника нельзя снять галочку основного участка</div>
          ),
        });
      } else {
        setNotification({
          status: "error",
          title: "Ошибка",
          subtitle: "Ошибка при добавлении обучения",
          isVisible: true,
          subtitle2: <div></div>,
        });
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "employee",
      headerName: "ФИО",
      type: "string",
      width: 230,
      editable: false,
      valueGetter: (value: any) => value.fio,
    },
    {
      field: "area",
      headerName: "Участок",
      type: "string",
      width: 130,
      editable: false,
      valueGetter: (value: any) => value.namearea,
    },
    {
      field: "mentor",
      headerName: "Наставник",
      type: "string",
      width: 230,
      editable: false,
      valueGetter: (value, row) => {
        if (row.mentor) {
          return row.mentor.fio;
        }
      },
    },
    {
      field: "numsmen",
      headerName: "Кол-во смен",
      width: 40,
      editable: false,
      valueGetter: (value, row) => {
        if (row.numsmen) {
          return row.numsmen;
        }
      },
    },
    {
      field: "date",
      headerName: "Дата начала обучения",
      width: 100,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.date) {
          return null;
        }
        const [day, month, year] = row.date.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
      valueFormatter: (value: any, row) => {
        if (!value || value.getTime() === new Date("1970-01-01").getTime()) {
          return "Нет данных";
        }
        const day = value.getDate().toString().padStart(2, "0");
        const month = (value.getMonth() + 1).toString().padStart(2, "0");
        const year = value.getFullYear();

        return `${day}.${month}.${year}`;
      },
    },
    {
      field: "dateend",
      headerName: "Дата окончания обучения",
      width: 100,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateend) {
          return null;
        }
        const [day, month, year] = row.dateend.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
      valueFormatter: (value: any, row) => {
        if (!value || value.getTime() === new Date("1970-01-01").getTime()) {
          return "Нет данных";
        }
        const day = value.getDate().toString().padStart(2, "0");
        const month = (value.getMonth() + 1).toString().padStart(2, "0");
        const year = value.getFullYear();

        return `${day}.${month}.${year}`;
      },
    },
    {
      field: "foremen",
      headerName: "Бригадир",
      width: 230,
      editable: false,
      valueGetter: (value, row) => {
        if (row.foremen) {
          return row.foremen.fio;
        }
      },
    },
    {
      field: "main",
      headerName: "Основной",
      width: 40,
      editable: false,
      valueFormatter: (value?: Boolean) => {
        return value ? "✓" : "";
      },
    },
    {
      field: "button",
      headerName: "",
      width: 280,
      renderCell: (params) => (
        <div className="act_btn_area">
          <select onChange={(e) => setSelectedAction(e.target.value)}>
            <option value="">Выберите действие</option>
            <option value="changeStatus">Изм. статус</option>
            <option value="printPish">Пищевка</option>
            <option value="printSiz">СИЗ</option>
            <option value="printStaz">Распоряжение о стажировке</option>
            <option value="printOtitb">ОТиТБ</option>
            <option value="printLna">Личная карточка</option>
            {/* <option value="printYdelectro">Удостоверение по ЭБ</option> */}
            <option value="printElectro">Прот. проверки ЭБ</option>
          </select>
          <Button
            variant="contained"
            onClick={() => handleAction(params.row.id, params.row.employee.id)}
          >
            Выполнить
          </Button>
        </div>
      ),
    },
  ];

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  const isSaveDisabled = !(
    selectedEmployee &&
    selectedArea &&
    selectedMentor &&
    selectedForemen &&
    isValidDate(new Date(selectedDate)) && 
    isValidDate(new Date(selectedDateEnd)) &&
    numSmen
  );

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
    setShowModal(false);
    dispatch(clearEmployee());
    setNextEmployeeModalBtn(false);
    setSelectedEmployee(null);
    setSelectedArea(null);
    setSelectedMentor(null);
    setSelectedDate("");
    setSelectedDateEnd("");
    setSelectedForemen(null);
    setSelectedSmen("");
    setSelectedMain(false);
    //navigate({ state: { showModal: false } });
  };

  return (
    <div className="area_cont">
      <Button
        variant="contained"
        onClick={openModal}
        style={{ marginTop: "20px" }}
      >
        Добавить/обновить обучение
      </Button>
      <div className="table_cont5">
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 12,
              },
            },
          }}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          pageSizeOptions={[12]}
          disableRowSelectionOnClick
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
        />
      </div>
      {/* Модальное окно для добавления */}
      {showModal && (
        <div className="modalTableTraining">
          <div className="modal-contentTableTraining">
            <h3>Добавление информации об обучении</h3>
            <div className="tr_cont_select">
              <Select
                id="employee-dropdown"
                value={selectedEmployee}
                options={employeeOptions}
                onChange={handleEmployeeChange}
                placeholder="Выберите сотрудника"
                styles={{}}
                isDisabled={!!employeeNext}
              />
            </div>

            <div className="tr_cont_select">
              <Select
                id="area-dropdown"
                value={selectedArea}
                options={areaOptions}
                onChange={(areaOptions) => setSelectedArea(areaOptions)}
                placeholder="Выберите участок"
              />
            </div>

            <div className="tr_cont_select">
              <Select
                id="mentor-dropdown"
                value={selectedMentor}
                options={mentorOptions}
                onChange={(mentorOptions) => setSelectedMentor(mentorOptions)}
                placeholder="Выберите наставника"
              />
            </div>

            <div className="fg">
              <label htmlFor="numsmen">Кол-во смен:</label>
              <input
                type="number"
                id="numsmen"
                name="numsmen"
                value={numSmen}
                onChange={(e) => setSelectedSmen(e.target.value)}
              />
            </div>

            <label htmlFor="date">Дата начала обучения:</label>
            <input
              type="date"
              id="date"
              name="date"
              style={{
                width: "96%",
                height: "28px",
                padding: "5px",
                border: "1px solid #b7b7b7",
                borderRadius: "5px",
              }}
              onChange={(e) => setSelectedDate(e.target.value)}
            />

            <label htmlFor="dateend">Дата окончания обучения:</label>
            <input
              type="date"
              id="dateend"
              name="dateend"
              style={{
                width: "96%",
                height: "28px",
                padding: "5px",
                border: "1px solid #b7b7b7",
                borderRadius: "5px",
              }}
              onChange={(e) => setSelectedDateEnd(e.target.value)}
            />

            <div className="tr_cont_select">
              <Select
                id="foremen-dropdown"
                value={selectedForemen}
                options={foremenOptions}
                onChange={(foremenOptions) =>
                  setSelectedForemen(foremenOptions)
                }
                placeholder="Выберите бригадира"
              />
            </div>

            <div className="fg2" style={{ marginTop: "0px" }}>
              <label htmlFor="main">Основной участок:</label>
              <input
                type="checkbox"
                id="main"
                checked={main}
                onChange={(e) => setSelectedMain(e.target.checked)}
              />
            </div>

            <div className="modal-actionsTableEmpl">
              {!nextEmployeeModalBtn && (
                <div className="modal-actionsTableEmpl">
                  <button onClick={confirm} disabled={isSaveDisabled}>
                    Добавить
                  </button>
                  <button onClick={() => setShowModal(false)}>Отмена</button>
                </div>
              )}

              {nextEmployeeModalBtn && (
                <div className="modal-actionsTableEmpl">
                  <button onClick={confirm} disabled={isSaveDisabled}>
                    Добавить
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          subtitle2={notification.subtitle2}
          onClose={hideNotification}
          isVisible={notification.isVisible}
        />
      )}
    </div>
  );
};

export default TableArea;
