import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export interface BriefingsEfes {
  fio: string;
  dateinput: string | null;
  dateonjob: string | null;
  datefire: string | null;
  dateelectro: string | null;
  numelectro: string;
}

export const briefingsEfesSlice = createApi({
  reducerPath: 'briefingsEfes',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['BriefingsEfes'],
  endpoints: (builder) => ({
    getBriefingsEfes: builder.query<BriefingsEfes[], void>({
      query: () => ({ url: '/efes/all_brief', method: 'GET' }),
      transformResponse: (response: BriefingsEfes[]) =>
        response.map((history) => ({
          ...history,
          dateinput: formatDate(history.dateinput),
          dateonjob: formatDate(history.dateonjob),
          datefire: formatDate(history.datefire),
          dateelectro: formatDate(history.dateelectro),
        })),
      providesTags: ['BriefingsEfes'],
    }),
  }),
});

export const { useGetBriefingsEfesQuery } = briefingsEfesSlice;