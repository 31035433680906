import React from 'react';

const Homepage = () => {
  const role = localStorage.getItem("role_efes");
  const fio = localStorage.getItem("fio_efes");

  const today = new Date();

  const formattedDate = new Intl.DateTimeFormat("ru-RU", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(today);

  const date_today = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  
  return (
    <div className="homepage_container">
      <div className="homepage_header">
        <div className="header_title_info">
          <div>{role}</div>
          <div>{fio}</div>
        </div>
        <img
          src="https://workspace.ru/upload/main/e35/9drglma6601fwku6py9zfurze84xtx7t/reviewsphoto.png"
          width="52px"
          height="52px"
          alt="avatar"
          style={{ marginRight: "15px", marginLeft: "auto" }}
        />
      </div>
      <div className="homepage_dashboard_container">
        <div
          className="block_employee_num"
          style={{ marginLeft: "15px", width: "380px" }}
        >
          <div className="circle_dashboard">
            <img
              src={require("../Assets/calendar.png")}
              alt=""
              width="35px"
              height="35px"
            />
          </div>
          <div className="info_employee_num">
            <div>Сегодня</div>
            <div>{date_today}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;