import React from 'react';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import { useGetSizEfesQuery } from '../Global/SizEfesSlice.ts';
import CircularProgress from '@mui/material/CircularProgress';

const Siz = () => {
    const { data: siz, error, isLoading } = useGetSizEfesQuery();

    const columns: GridColDef[] = [
        {
            field: "fio",
            headerName: "ФИО",
            width: 250,
            valueGetter: (value, row) => {
                if (row.fio !== ""){
                    return row.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "height",
            headerName: "Рост",
            width: 80,
            valueGetter: (value, row) => {
                if (row.height !== ""){
                    return row.height;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "Одежда",
            headerName: "Размер одежды",
            width: 80,
            valueGetter: (value, row) => {
                if (row.clothingsize !== ""){
                    return row.clothingsize;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "shoesize",
            headerName: "Обувь",
            width: 80,
            valueGetter: (value, row) => {
                if (row.shoesize !== ""){
                    return row.shoesize;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "headdress",
            headerName: "Головной убор",
            width: 80,
            valueGetter: (value, row) => {
                if (row.headdress !== ""){
                    return row.headdress;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "datesiz",
            headerName: "Дата выдачи",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.datesiz) {
                    return null;
                }
                const [day, month, year] = row.datesiz.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
    ];

    if (isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
        
    if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
          <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
          <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
      </div>
    ;

    return (
        <div className="active_employee_efes">
            <div className="container_siz_efes">
                <DataGrid
                    style={{
                    marginTop: 20,
                    }}
                    rows={siz ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>
        </div>
    );
}

export default Siz;