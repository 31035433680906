import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

export interface CommissionEfes {
    internshipsupervisor: string;
    headmanager: string;
    chairman: string;
    vice: string;
    manager1: string;
    manager2: string;
    date: string | null;
}

export const commissionEfesSlice = createApi({
    reducerPath: 'commissionEfes',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['CommissionEfes'],
    endpoints: (builder) => ({
      getCommissionEfes: builder.query<CommissionEfes[], void>({
        query: () => ({ url: '/efes/all_commission', method: 'GET' }),
        transformResponse: (response: CommissionEfes[]) =>
          response.map((commission) => ({
            ...commission,
            date: formatDate(commission.date),
          })),
        providesTags: ['CommissionEfes'],
      }),
    }),
  });
  
  export const { useGetCommissionEfesQuery } = commissionEfesSlice;