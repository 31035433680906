import React, { useState } from "react";
import Button from "@mui/material/Button";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import { useGetCommissionEfesQuery } from '../Global/CommissionEfesSlice.ts';
import IconButton from "@mui/material/IconButton";
import Notification from "../Components/Notification.tsx";
import axiosInstance from '../axiosConfigEfes.js';
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from '@mui/material/CircularProgress';

const SettingComission = () => {

    const { data: commission, error: commissionError, isLoading: isLoadingCommission, refetch: refetchCommission } = useGetCommissionEfesQuery();

    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    // Данные для комиссии
    const [showModalAddCommission, setShowModalAddCommission] = useState(false);

    const [chairman, setChairman] = useState("");
    const [vice, setVice] = useState("");
    const [commis1, setCommis1] = useState("");
    const [commis2, setCommis2] = useState("");
    const [commis3, setCommis3] = useState("");
    const [chairmanpost, setChairmanpost] = useState("");
    const [vicepost, setVicepost] = useState("");
    const [commis1post, setCommis1post] = useState("");
    const [commis2post, setCommis2post] = useState("");
    const [commis3post, setCommis3post] = useState("");
    const [dateCommission, setDateCommission] = useState(() => {
        const today = new Date();
        return today.toISOString().split("T")[0];
    });

    const columnsCommission: GridColDef[] = [
        {
          field: "chairman",
          headerName: "Председатель комиссии (ответственный за электробезопасность)",
          width: 280,
          type: "string",
          editable: false,
          valueGetter: (value, row) => {
            if (row.chairman != null){
              return row.chairmanpost + ", " + row.chairman;
            } else {
              return "Нет данных";
            }
          },
        },
        {
          field: "vice",
          headerName: "Заместитель председателя комиссии",
          width: 280,
          type: "string",
          editable: false,
          valueGetter: (value, row) => {
            if (row.vice != null){
              return row.vicepost + ", " + row.vice;
            } else {
              return "Нет данных";
            }
          },
        },
        {
          field: "commis1",
          headerName: "Член комиссии №1",
          width: 280,
          type: "string",
          editable: false,
          valueGetter: (value, row) => {
            if (row.commis1 != null){
              return row.commis1post + ", " + row.commis1;
            } else {
              return "Нет данных";
            }
          },
        },
        {
          field: "commis2",
          headerName: "Член комиссии №2",
          width: 280,
          type: "string",
          editable: false,
          valueGetter: (value, row) => {
            if (row.commis2 != null){
              return row.commis2post + ", " + row.commis2;
            } else {
              return "Нет данных";
            }
          },
        },
        {
          field: "commis3",
          headerName: "Член комиссии №3",
          width: 280,
          type: "string",
          editable: false,
          valueGetter: (value, row) => {
            if (row.commis3 != null){
              return row.commis3post + ", " + row.commis3;
            } else {
              return "Нет данных";
            }
          },
        },
        {
            field: "date",
            headerName: "Дата назнач.",
            width: 120,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
              if (!row.date) {
                return null;
              }
              const [day, month, year] = row.date.split(".");
              return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "button",
            headerName: "",
            width: 50,
            renderCell: (params) => (
              <div>
                <IconButton onClick={() => confirmDeleteCommission(params.row.id)}>
                    <DeleteIcon />
                </IconButton>
              </div>
            ),
        },
    ]

    // Добавление комиссии
    const confirmAddCommission = async () => {
        if (!chairman || !vice || !commis1 || !commis2 || !commis3 || !chairmanpost || !vicepost || !commis1post || !commis2post || !commis3post || !dateCommission) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Необходимо заполнить все поля",
            subtitle2: <div></div>,
            isVisible: true,
          });
          return;
        }
    
        const data = {
          chairman: chairman,
          vice: vice,
          commis1: commis1,
          commis2: commis2,
          commis3: commis3,
          chairmanpost: chairmanpost,
          vicepost: vicepost,
          commis1post: commis1post,
          commis2post: commis2post,
          commis3post: commis3post,
          date: dateCommission,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });

            await axiosInstance.post(`/efes/add_new_commission`, data);
            
            setShowModalAddCommission(false);
            refetchCommission();
      
            setNotification({
              status: "success",
              title: "Успешно",
              subtitle: "Комиссия добавлена",
              subtitle2: <div></div>,
              isVisible: true,
            });
      
        } catch (error: any) {
            const errorMessage =
              error.response?.data || "Ошибка при добавлении комиссии"; 
        
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: errorMessage,
              subtitle2: <div></div>,
              isVisible: true,
            });
          }
    }

    // Удаление комиссии
    const confirmDeleteCommission = async (id) => {
        const confirmLogout = window.confirm("Точно хотите удалить комисиию?");
        if (!confirmLogout) return;

        const data1 = {
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };
    
        try {
    
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
            await axiosInstance.delete(`/efes/delete_commission/${id}`, { data: data1 });

            refetchCommission();
    
            setNotification({
                status: "success",
                title: "Успешно",
                subtitle: "Комиссия удалена",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
        } catch (error) {
            setNotification({
                status: "error",
                title: "Ошибка",
                subtitle: "Ошибка при удалении комиссии",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    if (isLoadingCommission)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "calc(100vh - 60px)"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
    
    if (commissionError) 
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "calc(100vh - 60px)"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
                <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
                <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
            </div>
        </div>
    ;

    return (
        <div>
            <div className="homepage_dashboard_container" style={{ width: "fit-content" }}>
                <div
                    className="block_employee_num_settings_efes"
                    style={{ marginTop: "15px", width: "950px" }}
                    >
                    <div style={{ fontWeight: "700", fontSize: "18px", color: "#2F3747", textAlign: "center" }}>Комиссия</div>
                    <Button
                        variant="contained"
                        onClick={() => setShowModalAddCommission(true)}
                        style={{ marginTop: "10px", width: "100%", fontWeight: "600", fontSize: "12px" }}
                        >
                        Добавить комиссию
                    </Button>
                    <div className="table_settings_container_efes">
                        <DataGrid
                            style={{
                                marginTop: 20,
                                height: 319,
                            }}
                            rows={commission ?? []}
                            columns={columnsCommission}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 4,
                                },
                            },
                            }}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            pageSizeOptions={[4]}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            slotProps={{ toolbar: { showQuickFilter: true } }}
                        />
                    </div>
                </div>
            </div>

            {/* Модальное окно для добавления комиссии*/}
            {showModalAddCommission && (
                <div className="modal_add_commission_employee_efes">
                    <div className="modal_cont_add_commission_employee_efes">
                        <div className="title_employee_efes">Добавление новой комиссии</div>

                        {/* №1 */}
                        <div style={{fontFamily: '"Montserrat", sans-serif', fontWeight: "700", fontSize: "15px"}}>Председатель комиссии (ответственный за электробезопасность)</div>
                        <div style={{marginTop: "10px"}}>
                          <label htmlFor="internshipsupervisor">ФИО</label>
                          <input
                              type="text"
                              name="internshipsupervisor"
                              value={chairman}
                              onChange={(e) => setChairman(e.target.value)}
                              style={{width: "275px"}}
                          ></input>
                        </div>
                        <div style={{marginTop: "10px"}}>
                          <label htmlFor="internshipsupervisor">Должность</label>
                          <input
                              type="text"
                              name="internshipsupervisor"
                              value={chairmanpost}
                              onChange={(e) => setChairmanpost(e.target.value)}
                              style={{width: "223px"}}
                          ></input>
                        </div>

                        {/* №2 */}
                        <div style={{fontFamily: '"Montserrat", sans-serif', fontWeight: "700", fontSize: "15px", marginTop: "20px"}}>Заместитель председателя комиссии</div>
                        <div style={{marginTop: "10px"}}>
                          <label htmlFor="headmanager">ФИО</label>
                          <input
                              type="text"
                              name="headmanager"
                              value={vice}
                              onChange={(e) => setVice(e.target.value)}
                              style={{width: "275px"}}
                          ></input>
                        </div>
                        <div style={{marginTop: "10px"}}>
                          <label htmlFor="internshipsupervisor">Должность</label>
                          <input
                              type="text"
                              name="internshipsupervisor"
                              value={vicepost}
                              onChange={(e) => setVicepost(e.target.value)}
                              style={{width: "223px"}}
                          ></input>
                        </div>
                        
                        {/* №3 */}
                        <div style={{fontFamily: '"Montserrat", sans-serif', fontWeight: "700", fontSize: "15px", marginTop: "20px"}}>Член комиссии №1</div>
                        <div style={{marginTop: "10px"}}>
                          <label htmlFor="chairman">ФИО</label>
                          <input
                              type="text"
                              name="chairman"
                              value={commis1}
                              onChange={(e) => setCommis1(e.target.value)}
                              style={{width: "275px"}}
                          ></input>
                        </div>
                        <div style={{marginTop: "10px"}}>
                          <label htmlFor="internshipsupervisor">Должность</label>
                          <input
                              type="text"
                              name="internshipsupervisor"
                              value={commis1post}
                              onChange={(e) => setCommis1post(e.target.value)}
                              style={{width: "223px"}}
                          ></input>
                        </div>

                        {/* №4 */}
                        <div style={{fontFamily: '"Montserrat", sans-serif', fontWeight: "700", fontSize: "15px", marginTop: "20px"}}>Член комиссии №2</div>
                        <div style={{marginTop: "10px"}}>
                          <label htmlFor="chairman">ФИО</label>
                          <input
                              type="text"
                              name="chairman"
                              value={commis2}
                              onChange={(e) => setCommis2(e.target.value)}
                              style={{width: "275px"}}
                          ></input>
                        </div>
                        <div style={{marginTop: "10px"}}>
                          <label htmlFor="internshipsupervisor">Должность</label>
                          <input
                              type="text"
                              name="internshipsupervisor"
                              value={commis2post}
                              onChange={(e) => setCommis2post(e.target.value)}
                              style={{width: "223px"}}
                          ></input>
                        </div>

                        {/* №5 */}
                        <div style={{fontFamily: '"Montserrat", sans-serif', fontWeight: "700", fontSize: "15px", marginTop: "20px"}}>Член комиссии №3</div>
                        <div style={{marginTop: "10px"}}>
                          <label htmlFor="chairman">ФИО</label>
                          <input
                              type="text"
                              name="chairman"
                              value={commis3}
                              onChange={(e) => setCommis3(e.target.value)}
                              style={{width: "275px"}}
                          ></input>
                        </div>
                        <div style={{marginTop: "10px"}}>
                          <label htmlFor="internshipsupervisor">Должность</label>
                          <input
                              type="text"
                              name="internshipsupervisor"
                              value={commis3post}
                              onChange={(e) => setCommis3post(e.target.value)}
                              style={{width: "223px"}}
                          ></input>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <label htmlFor="date" style={{fontFamily: '"Montserrat", sans-serif', fontWeight: "700", fontSize: "15px"}}>Дата назначения:</label>
                            <input
                                type="date"
                                id="date"
                                name="date"
                                value={dateCommission}
                                onChange={(e) => setDateCommission(e.target.value)}
                                style={{width: "171px"}}
                            />
                        </div>

                        <div className="modal_act_fir" style={{ marginLeft: "auto", marginRight: "auto"}}>
                            <button onClick={confirmAddCommission}>Добавить</button>
                            <button onClick={() => setShowModalAddCommission(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {notification.isVisible && (
                <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                onClose={hideNotification}
                isVisible={notification.isVisible}
                subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default SettingComission;