import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from "react-router-dom";
import EfesEmployee from "./Efes/Pages/Employee.tsx";
import EfesNavbar from './Efes/Components/Navbar.tsx';
import EfesLogin from './Efes/Pages/Login.tsx';
import './MarsPets/Styles/login.scss';
import Homepage from './Efes/Pages/Homepage.tsx';
import { useEffect } from 'react';
import History from "./Efes/Pages/History.tsx";
import Briefings from "./Efes/Pages/Briefings.tsx";
import Siz from "./Efes/Pages/Siz.tsx";
import FiredEmployee from "./Efes/Pages/FiredEmployee.tsx";
import Settings from "./Efes/Pages/Settings.tsx";
import NormalSiz from "./Efes/Pages/NormalSiz.tsx";
import Print from "./Efes/Pages/Print.tsx";
import Trainings from "./Efes/Pages/Trainings.tsx";
import Driver from "./Efes/Pages/Driver.tsx";

function EfesRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const hideNavbar = location.pathname === '/efes/login';

  // Получаем статус аутентификации из localStorage
  const isAuthenticated = !!localStorage.getItem('token_efes');

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== '/efes/login') {
      navigate('/efes/login');
    } else if (isAuthenticated && location.pathname === '/efes/login') {
      navigate('/efes/');
    }
  }, [location.pathname, navigate, isAuthenticated]);

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {!hideNavbar && <EfesNavbar />}
      <Routes>
        <Route path="/login" element={<EfesLogin />} />
        {isAuthenticated ? (
          <>
            <Route path="/employee" element={<EfesEmployee />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/history" element={<History />} />
            <Route path="/briefings" element={<Briefings />} />
            <Route path="/siz" element={<Siz />} />
            <Route path="/fired" element={<FiredEmployee />} />
            <Route path="/settings" element={<Settings/>} />
            <Route path="/normal_siz" element={<NormalSiz />} />
            <Route path="/print" element={<Print />} />
            <Route path="/trainings" element={<Trainings />} />
            <Route path="/driver" element={<Driver />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/efes/login" />} />
        )}
      </Routes>
    </div>
  );
}
  
export default EfesRoutes;