import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import { FiredEmployeeEfes, useGetFiredEmployeeEfesQuery } from '../Global/FiredEmployeeEfesSlice.ts';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import Button from "@mui/material/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from "@mui/material/IconButton";
import Notification from "../Components/Notification.tsx";
import axiosInstance from '../axiosConfigEfes.js';

const FiredEmployee = () => {
    const { data: firedEmployee, error, isLoading, refetch } = useGetFiredEmployeeEfesQuery();
    const [startD, setStartD] = useState<Dayjs | null>(null);
    const [endD, setEndD] = useState<Dayjs | null>(null);

    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    const calculateWorkExperience = (startDate, endDate) => {
        const now = new Date(endDate);
        const start = new Date(startDate);
    
        let years = now.getFullYear() - start.getFullYear();
        let months = now.getMonth() - start.getMonth();
        let days = now.getDate() - start.getDate();
    
        if (months < 0) {
          years--;
          months += 12;
        }
    
        if (days < 0) {
          months--;
          const lastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
          days += lastMonth.getDate();
        }
    
        const totalMonths = years * 12 + months;
    
        let experience = "";
        if (years > 0) {
          experience += `${years} ${
            years === 1 ? "год" : years > 1 && years < 5 ? "года" : "лет"
          }`;
        }
    
        if (months > 0) {
          if (experience) experience += " ";
          experience += `${months} ${
            months === 1 ? "месяц" : months > 1 && months < 5 ? "месяца" : "месяцев"
          }`;
        }
    
        if (years === 0 && months === 0 && days >= 0) {
          experience =
            days === 0
              ? "менее дня"
              : `${days} ${
                  days === 1 ? "день" : days > 1 && days < 5 ? "дня" : "дней"
                }`;
        }
    
        return { formattedExperience: experience.trim(), totalMonths };
    };

    const columns: GridColDef[] = [
        {
            field: "fio",
            headerName: "ФИО",
            width: 250,
            valueGetter: (value, row) => {
                if (row.fio !== ""){
                    return row.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "comment",
            headerName: "Причина увольнения",
            width: 250,
            valueGetter: (value, row) => {
                if (row.comment !== ""){
                    return row.comment;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "dateemployment",
            headerName: "Дата трудоустройства",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateemployment) {
                    return null;
                }
                const [day, month, year] = row.dateemployment.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "datefired",
            headerName: "Дата увольнения",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.datefired) {
                    return null;
                }
                const [day, month, year] = row.datefired.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
          field: "dateedst",
          headerName: "Стаж",
          width: 100,
          editable: false,
          valueGetter: (value, row) => {
            if (!row.dateemployment) {
              return null;
            }
            const [day, month, year] = row.dateemployment.split(".");
            const startDate = new Date(`${year}-${month}-${day}`);
            const [day2, month2, year2] = row.datefired.split(".");
            const endDate = new Date(`${year2}-${month2}-${day2}`);
            const { formattedExperience } = calculateWorkExperience(startDate, endDate);
            return formattedExperience;
          },
        },
        {
          field: "button",
          headerName: "",
          width: 80,
          renderCell: (params) => (
            <div>
              {localStorage.getItem("role_efes") !== "Бригадир" && (
                <IconButton onClick={() => confirmRefrFired(params.row.id)}>
                  <AutorenewIcon />
                </IconButton>
              )}
            </div>
          ),
      },
    ];

    const confirmRefrFired = async (id) => {
      const confirmLogout = window.confirm("Точно хотите восстановить сотрудника?");
      if (!confirmLogout) return;

      const data1 = {
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
      };
  
      try {
  
          setNotification({
              status: "loading",
              title: "Обновление данных",
              subtitle: "Пожалуйста, подождите...",
              subtitle2: <div></div>,
              isVisible: true,
          });
  
          await axiosInstance.delete(`/efes/delete_firedempl/${id}`, { data: data1 });

          refetch();
  
          setNotification({
              status: "success",
              title: "Успешно",
              subtitle: "Сотрудник восстановлен",
              subtitle2: <div></div>,
              isVisible: true,
          });
  
      } catch (error) {
          setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: "Ошибка при восстановлении",
              subtitle2: <div></div>,
              isVisible: true,
          });
      }
  };

    const handleReset = () => {
        setStartD(null);
        setEndD(null);
    };

    const filterByDateRange = (data, start, end) => {
      if (!start && !end) return data;

      return data.filter((row) => {
          const [day, month, year] = row.datefired.split(".");
          const dateFired = new Date(`${year}-${month}-${day}`);

          const startDate = start ? new Date(start.year(), start.month(), start.date()) : null;
          const endDate = end ? new Date(end.year(), end.month(), end.date(), 23, 59, 59) : null;

          if (startDate && dateFired < startDate) return false; 
          if (endDate && dateFired > endDate) return false;

          return true;
      });
    };

    const filteredData = filterByDateRange(firedEmployee ?? [], startD, endD);

    if (isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
        
    if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
          <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
          <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
      </div>
    ;

    return (
        <div className="active_employee_efes">
            <div className="time_container_fired_efes">
                <div className="time_title">Отобразить данные в промежутке от</div>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                    value={startD}
                    onChange={(date) => setStartD(date)}
                    label="дд.мм.гггг"
                    />
                </DemoContainer>
                </LocalizationProvider>
                <div className="time_title2">до</div>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                    value={endD}
                    onChange={(date2) => setEndD(date2)}
                    label="дд.мм.гггг"
                    />
                </DemoContainer>
                </LocalizationProvider>
                <Button
                variant="contained"
                onClick={handleReset}
                style={{
                    width: "120px",
                    height: "37px",
                    marginTop: "8px",
                    marginLeft: "20px",
                }}
                >
                Сбросить
                </Button>
            </div>
            <div className="container_fired_efes" style={{width:"900px"}}>
                <DataGrid
                    style={{
                    marginTop: 20,
                    }}
                    rows={filteredData ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>

            {notification.isVisible && (
                <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                onClose={hideNotification}
                isVisible={notification.isVisible}
                subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default FiredEmployee;