import React from 'react';
import { useGetHistoryEfesQuery } from '../Global/HistoryEfesSlice.ts';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import CircularProgress from '@mui/material/CircularProgress';

const History = () => {
    const { data: history, error, isLoading } = useGetHistoryEfesQuery();

    const columns: GridColDef[] = [
        {
            field: "typeedit",
            headerName: "Операция",
            width: 110,
            valueGetter: (value, row) => {
                if (row.typeedit !== ""){
                    return row.typeedit;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "dateedit",
            headerName: "Дата операции",
            width: 120,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
            if (!row.dateedit) {
                return null;
            }
            const [day, month, year] = row.dateedit.split(".");
            return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "tableedit",
            headerName: "Таблица",
            width: 120,
            valueGetter: (value, row) => {
                if (row.tableedit !== ""){
                    return row.tableedit;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "beforetext",
            headerName: "Старая запись",
            width: 300,
            valueGetter: (value, row) => {
                if (row.beforetext !== ""){
                    return row.beforetext;
                } else {
                    return "";
                }
            },
        },
        {
            field: "aftertext",
            headerName: "Новая запись",
            width: 300,
            valueGetter: (value, row) => {
                if (row.aftertext !== ""){
                    return row.aftertext;
                } else {
                    return "";
                }
            },
        },
        {
            field: "dopinfo",
            headerName: "Доп. информация",
            width: 300,
            valueGetter: (value, row) => {
                if (row.dopinfo !== ""){
                    return row.dopinfo;
                } else {
                    return "";
                }
            },
        },
        {
            field: "roleakk",
            headerName: "Пользователь",
            width: 100,
            valueGetter: (value, row) => {
                if (row.roleakk !== ""){
                    return row.roleakk;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "fioakk",
            headerName: "ФИО пользователя",
            width: 250,
            valueGetter: (value, row) => {
                if (row.fioakk !== ""){
                    return row.fioakk;
                } else {
                    return "Нет данных";
                }
            },
        },
        
    ];

    if (isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
      ;
        
      if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
          <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
          <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
      </div>
      ;

    return (
        <div className="active_employee_efes">
            <div className="container_history_employee_efes">
                <DataGrid
                    style={{
                    marginTop: 20,
                    }}
                    rows={history ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>
        </div>
    );
}

export default History;