import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import { useGetBriefingsEfesQuery } from '../Global/BriefingsEfesSlice.ts';
import CircularProgress from '@mui/material/CircularProgress';import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Notification from "../Components/Notification.tsx";
import axiosInstance from '../axiosConfigEfes.js';
import "../Styles/Briefings.scss";

const Briefings = () => {
    const { data: briefings, error, isLoading, refetch } = useGetBriefingsEfesQuery();

    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
    const [showModalUpd, setShowModalUpd] = useState(false);

    const [dateinput, setDateinput] = useState("");
    const [dateonjob, setDateonjob] = useState("");
    const [datefire, setDatefire] = useState("");
    const [dateelectro, setDateelectro] = useState("");
    const [electro, setElectro] = useState("");

    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
      });
    
      const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
      };

    const columns: GridColDef[] = [
        {
            field: "fio",
            headerName: "ФИО",
            width: 250,
            valueGetter: (value, row) => {
                if (row.fio !== ""){
                    return row.fio;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "dateinput",
            headerName: "Вводный",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
            if (!row.dateinput) {
                return null;
            }
            const [day, month, year] = row.dateinput.split(".");
            return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "dateonjob",
            headerName: "На рабочем месте",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateonjob) {
                    return null;
                }
                const [day, month, year] = row.dateonjob.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "datefire",
            headerName: "ПБ",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.datefire) {
                    return null;
                }
                const [day, month, year] = row.datefire.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "dateelectro",
            headerName: "ЭБ",
            width: 100,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
                if (!row.dateinput) {
                    return null;
                }
                const [day, month, year] = row.dateelectro.split(".");
                return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "numelectro",
            headerName: "Группа ЭБ",
            width: 90,
            valueGetter: (value, row) => {
                if (row.numelectro !== ""){
                    return row.numelectro;
                } else {
                    return "Нет данных";
                }
            },
        },
        {
            field: "button",
            headerName: "Действия",
            width: 60,
            renderCell: (params) => (
              <div>
                <IconButton onClick={() => handleEdit(params.row.id, params.row.fio)}>
                  <EditIcon />
                </IconButton>
              </div>
            ),
        },
    ];

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleEdit = async (id, fio) => {
        setNotification({
            status: "loading",
            title: "Получение данных",
            subtitle: "Пожалуйста, подождите...",
            subtitle2: <div></div>,
            isVisible: true,
        });

        await axiosInstance
          .get(`/efes/get_employee/${id}`)
          .then((response) => {
            const employee = response.data;
            setDateinput(formatDate(employee.dateinput));
            setDateonjob(formatDate(employee.dateonjob));
            setDatefire(formatDate(employee.datefire));
            setDateelectro(formatDate(employee.dateelectro));
            setElectro(employee.numelectrosecurity);
          })
          .catch((error) => {
            console.error(
              "Ошибка при получении данных о сотруднике для редактирования:",
              error
            );
        })

        hideNotification();

        setSelectedEmployeeId(id);
        setSelectedEmployeeFio(fio);
        setShowModalUpd(true);
    };

    const confirmUpd = async () => {
        const formattedDateinput = new Date(dateinput).toISOString();
        const formattedDateonjob = new Date(dateonjob).toISOString();
        const formattedDatefire = new Date(datefire).toISOString();
        const formattedDateelectro = new Date(dateelectro).toISOString();
    
        const empData = {
          id: selectedEmployeeId,
          dateinput: formattedDateinput,
          dateonjob: formattedDateonjob,
          datefire: formattedDatefire,
          dateelectro: formattedDateelectro,
          numelectro: electro,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
      };
    
        try {
    
          setNotification({
              status: "loading",
              title: "Обновление данных",
              subtitle: "Пожалуйста, подождите...",
              subtitle2: <div></div>,
              isVisible: true,
          });
    
          await axiosInstance.put(`/efes/edit_brief`, empData);
          
          setShowModalUpd(false);
          refetch();
    
          setNotification({
            status: "success",
            title: "Успешно",
            subtitle: "Инструктажи отредактированы",
            subtitle2: <div></div>,
            isVisible: true,
          });
    
        } catch (error) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при обновлении",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      };

    if (isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
        
    if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
          <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
          <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
        </div>
      </div>
    ;

    return (
        <div className="active_employee_efes">
            <div className="container_briefings_efes">
                <DataGrid
                    style={{
                    marginTop: 20,
                    }}
                    rows={briefings ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 12,
                        },
                    },
                    }}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[12]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                    toolbar: GridToolbar,
                    }}
                    slotProps={{ toolbar: { showQuickFilter: true } }}
                />
            </div>
            {/* Модальное окно для редактирования */}
            {showModalUpd && (
                <div className="modal_brief_employee_efes">
                    <div className="modal_cont_brief_employee_efes">
                        <h3 className='title_employee_efes' style={{height: "50px"}}>Редактирование инструктажей</h3>
                        <label style={{display:"flex", flexDirection: "row"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectedEmployeeFio}</div></label>

                        <div className="dat">
                            <label htmlFor="dateinput">Дата вводного инструктажа:</label>
                            <input
                                type="date"
                                id="dateinput"
                                name="dateinput"
                                value={dateinput}
                                onChange={(e) => setDateinput(e.target.value)}
                                style={{marginTop: "15px", marginBottom: "10px", marginLeft: "25px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="dateonjob">Дата на рабочем месте:</label>
                            <input
                                type="date"
                                id="dateonjob"
                                name="dateonjob"
                                value={dateonjob}
                                onChange={(e) => setDateonjob(e.target.value)}
                                style={{marginBottom: "10px", marginLeft: "65px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="datefire">Дата пожаробезопасности:</label>
                            <input
                                type="date"
                                id="datefire"
                                name="datefire"
                                value={datefire}
                                onChange={(e) => setDatefire(e.target.value)}
                                style={{ marginBottom: "10px", marginLeft: "33px"}}
                            />
                        </div>

                        <div className="dat">
                            <label htmlFor="dateelectro">Дата электробезопасности:</label>
                            <input
                                type="date"
                                id="dateelectro"
                                name="dateelectro"
                                value={dateelectro}
                                onChange={(e) => setDateelectro(e.target.value)}
                                style={{marginBottom: "10px", marginLeft: "30px"}}
                            />
                        </div>

                        <label
                            htmlFor="electro-dropdown"
                        >
                            Группа электробезопасности:
                        </label>
                        <select
                            id="electro-dropdown"
                            value={electro}
                            style={{ width: "100%", marginBottom: "10px", marginTop: "10px", height: "22px"}}
                            onChange={(e) => setElectro(e.target.value)}
                        >
                            <option value="" disabled>
                            Выберите группу электробезопасности
                            </option>
                            <option value="1" key="1" >
                            Первая
                            </option>
                            <option value="2" key="2">
                            Вторая
                            </option>
                            <option value="3" key="3">
                            Третья
                            </option>
                            <option value="4" key="4">
                            Четвертая
                            </option>
                        </select>

                        <div className="modal_act_fir">
                            <button onClick={confirmUpd}>Обновить</button>
                            <button onClick={() => setShowModalUpd(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {notification.isVisible && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    subtitle={notification.subtitle}
                    onClose={hideNotification}
                    isVisible={notification.isVisible}
                    subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default Briefings;