import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

export interface ProgramBrief {
    name: string;
    numprog: string;
    dateyt: string | null;
    datepe: string | null;
    post: {
        id: number;
        namepost: string;
    };
}

export const programBriefEfesSlice = createApi({
    reducerPath: 'programBriefEfes',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['ProgramBriefEfes'],
    endpoints: (builder) => ({
      getProgramBriefEfes: builder.query<ProgramBrief[], void>({
        query: () => ({ url: '/efes/all_program_brief', method: 'GET' }),
        transformResponse: (response: ProgramBrief[]) =>
          response.map((programBrief) => ({
            ...programBrief,
            dateyt: formatDate(programBrief.dateyt),
            datepe: formatDate(programBrief.datepe),
          })),
        providesTags: ['ProgramBriefEfes'],
      }),
    }),
  });
  
  export const { useGetProgramBriefEfesQuery } = programBriefEfesSlice;