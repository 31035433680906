import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export interface History {
    typeedit: string;
    dateedit: string | null;
    tableedit: string;
    beforetext: string;
    aftertext: string;
    fioakk: string;
    roleakk: string;
    dopinfo: string;
}

export const historyEfesSlice = createApi({
  reducerPath: 'historyEfes',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['HistoryEfes'],
  endpoints: (builder) => ({
    getHistoryEfes: builder.query<History[], void>({
      query: () => ({ url: '/efes/history/last-week', method: 'GET' }),
      transformResponse: (response: History[]) =>
        response.map((history) => ({
          ...history,
          dateedit: formatDate(history.dateedit),
        })),
      providesTags: ['HistoryEfes'],
    }),
  }),
});

export const { useGetHistoryEfesQuery } = historyEfesSlice;