import React, { useState } from "react";
import { useGetPostQuery, useGetNonActivePostsQuery } from "../Global/PostEfesSlice.ts";
import Button from "@mui/material/Button";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import Notification from "../Components/Notification.tsx";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import IconButton from "@mui/material/IconButton";
import axiosInstance from '../axiosConfigEfes.js';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import CircularProgress from '@mui/material/CircularProgress';

const SettingPost = () => {

    const { data: postOptions, error: activePostError, isLoading: isLoadingActivePost, refetch: refetchActivePost } = useGetPostQuery();
    const { data: postNonOptions, error: nonActivePostError, isLoading: isLoadingNonActivePost, refetch: refetchNonPost } = useGetNonActivePostsQuery();

    const [showModalAddPost, setShowModalAddPost] = useState(false);
    const [namepost, setNamepost] = useState("");
    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    const columns: GridColDef[] = [
        {
          field: "namepost",
          headerName: "Название должности",
          width: 280,
          type: "string",
          editable: false,
          valueGetter: (value, row) => {
            if (row.namepost != null){
              return row.namepost;
            } else {
              return "Нет данных";
            }
          },
        },
        {
            field: "button",
            headerName: "",
            width: 30,
            renderCell: (params) => (
                <div>
                    <IconButton onClick={() => handleEditStatus(params.row.namepost)}>
                        <KeyboardDoubleArrowRightIcon />
                    </IconButton>
                </div>
            ),
        }
    ]

    const columnsNon: GridColDef[] = [
        {
          field: "namepost",
          headerName: "Название должности",
          width: 280,
          type: "string",
          editable: false,
          valueGetter: (value, row) => {
            if (row.namepost != null){
              return row.namepost;
            } else {
              return "Нет данных";
            }
          },
        },
        {
            field: "button",
            headerName: "",
            width: 30,
            renderCell: (params) => (
                <div>
                    <IconButton onClick={() => handleEditStatus(params.row.namepost)}>
                        <KeyboardDoubleArrowRightIcon sx={{ rotate: "180deg"}} />
                    </IconButton>
                </div>
            ),
        }
    ]

    const handleEditStatus = async ( namepostinf ) => {
        const data = {
            namepost: namepostinf,
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };
        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });

            await axiosInstance.put(`/efes/toggle_status`, data);
            
            setShowModalAddPost(false);
            refetchActivePost();
            refetchNonPost();
      
            setNotification({
              status: "success",
              title: "Успешно",
              subtitle: "Статус должности изменен",
              subtitle2: <div></div>,
              isVisible: true,
            });
      
        } catch (error) {
            setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при изменении должности",
            subtitle2: <div></div>,
            isVisible: true,
            });
        }
    }

    const confirmAddPost = async () => {
        if (!namepost) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Необходимо указать название",
            subtitle2: <div></div>,
            isVisible: true,
          });
          return;
        }
    
        const empData = {
          namepost: namepost,
          fio_akk: localStorage.getItem("fio_efes"),
          role_akk: localStorage.getItem("role_efes"),
        };

        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });

            await axiosInstance.post(`/efes/add_new_post`, empData);
            
            setShowModalAddPost(false);
            refetchActivePost();
            refetchNonPost();
      
            setNotification({
              status: "success",
              title: "Успешно",
              subtitle: "Должность добавлена",
              subtitle2: <div></div>,
              isVisible: true,
            });
      
        } catch (error) {
            setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Ошибка при добавлении должности",
            subtitle2: <div></div>,
            isVisible: true,
            });
        }
    }

    if (isLoadingActivePost || isLoadingNonActivePost)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "calc(100vh - 60px)"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
    
    if (activePostError || nonActivePostError) 
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "calc(100vh - 60px)"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
                <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
                <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
            </div>
        </div>
    ;
    
    return (
        <div style={{ paddingLeft: "20px" }}>
            <div className="homepage_header">
                <div className="header_title_info" style={{ fontWeight: "700", fontSize: "25px", color: "#2F3747" }}>Настройки должностей</div>
            </div>
            <div className="homepage_dashboard_container" style={{ width: "fit-content" }}>
                <div
                    className="block_employee_num_settings_efes"
                    style={{ marginTop: "15px", width: "750px" }}
                    >
                    {/* <div style={{ fontWeight: "700", fontSize: "18px", color: "#2F3747", textAlign: "center" }}>Должности</div> */}
                    <Button
                        variant="contained"
                        onClick={() => setShowModalAddPost(true)}
                        style={{ marginTop: "10px", width: "100%", fontWeight: "600", fontSize: "12px" }}
                        >
                        Добавить должность
                    </Button>
                    <div className="table_settings_container_efes">
                        <DataGrid
                            style={{
                                marginTop: 20,
                                height: 319,
                                backgroundColor: "#d9ffd2"
                            }}
                            rows={postOptions ?? []}
                            columns={columns}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 4,
                                },
                            },
                            }}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            pageSizeOptions={[4]}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            slotProps={{ toolbar: { showQuickFilter: true } }}
                        />

                        <ImportExportIcon sx={{ fontSize: 35, color: "#1976d2", rotate: "90deg",  marginLeft: "15px", marginRight: "15px"}}/>

                        <DataGrid
                            style={{
                                marginTop: 20,
                                height: 319,
                                backgroundColor: "#ffdad2"
                            }}
                            rows={postNonOptions ?? []}
                            columns={columnsNon}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 4,
                                },
                            },
                            }}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            pageSizeOptions={[4]}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            slotProps={{ toolbar: { showQuickFilter: true } }}
                        />
                    </div>
                </div>
            </div>
            {/* Модальное окно для добавления должности */}
            {showModalAddPost && (
                <div className="modal_add_post_employee_efes">
                    <div className="modal_cont_add_post_employee_efes">
                        <div className="title_employee_efes">Добавление новой должности</div>

                        <label htmlFor="namepost">Название</label>
                        <input
                            type="text"
                            name="namepost"
                            value={namepost}
                            onChange={(e) => setNamepost(e.target.value)}
                            style={{width: "234px"}}
                        ></input>

                        <div className="modal_act_fir">
                            <button onClick={confirmAddPost}>Добавить</button>
                            <button onClick={() => setShowModalAddPost(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {notification.isVisible && (
                <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                onClose={hideNotification}
                isVisible={notification.isVisible}
                subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default SettingPost;