import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export interface NormalSizEfes {
    name: string;
    model: string;
    col: string;
    sum: number;
    type: string;
    main: boolean;
    post: {
        id: number;
        namepost: string;
    };
}

export const normalSizEfesSlice = createApi({
    reducerPath: 'normalSizEfes',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['NormalSizEfes'],
    endpoints: (builder) => ({
      getNormalSizEfes: builder.query<NormalSizEfes[], void>({
        query: () => ({ url: '/efes/all_normal', method: 'GET' }),
        transformResponse: (response: NormalSizEfes[]) =>
          response.map((normal) => ({
            ...normal,
          })),
        providesTags: ['NormalSizEfes'],
      }),
    }),
  });
  
  export const { useGetNormalSizEfesQuery } = normalSizEfesSlice;