import { configureStore } from '@reduxjs/toolkit';
import { employeeActiveSlice } from './Efes/Global/EmployeeActiveSlice.ts';
import { postSlice } from './Efes/Global/PostEfesSlice.ts';
import { historyEfesSlice } from './Efes/Global/HistoryEfesSlice.ts';
import { briefingsEfesSlice } from './Efes/Global/BriefingsEfesSlice.ts';
import { sizEfesSlice } from './Efes/Global/SizEfesSlice.ts';
import { firedEmployeeEfesSlice } from './Efes/Global/FiredEmployeeEfesSlice.ts';
import { normalSizEfesSlice } from './Efes/Global/NormalSizEfesSlice.ts';
import { commissionEfesSlice } from './Efes/Global/CommissionEfesSlice.ts';
import { programBriefEfesSlice } from './Efes/Global/ProgramBriefEfesSlice.ts';
import { allProgrammBriefSlice } from './Efes/Global/AllProgrammBrief.ts';
import { trainingsEfesSlice } from './Efes/Global/TrainingsEfes.ts';
import { employeeEfesReducer } from './Efes/Global/EmployeeActiveSlice.ts';
import { areaEfesSlice } from './Efes/Global/AreaEfesSlice.ts';
import { driverEfesSlice } from './Efes/Global/DriverEfesSlice.ts';

export const store = configureStore({
  reducer: {
    [employeeActiveSlice.reducerPath]: employeeActiveSlice.reducer,
    [postSlice.reducerPath]: postSlice.reducer,
    [historyEfesSlice.reducerPath]: historyEfesSlice.reducer,
    [briefingsEfesSlice.reducerPath]: briefingsEfesSlice.reducer,
    [sizEfesSlice.reducerPath]: sizEfesSlice.reducer,
    [firedEmployeeEfesSlice.reducerPath]: firedEmployeeEfesSlice.reducer,
    [normalSizEfesSlice.reducerPath]: normalSizEfesSlice.reducer,
    [commissionEfesSlice.reducerPath]: commissionEfesSlice.reducer,
    [programBriefEfesSlice.reducerPath]: programBriefEfesSlice.reducer,
    [allProgrammBriefSlice.reducerPath]: allProgrammBriefSlice.reducer,
    [trainingsEfesSlice.reducerPath]: trainingsEfesSlice.reducer,
    employeeEfesState: employeeEfesReducer,
    [areaEfesSlice.reducerPath]: areaEfesSlice.reducer,
    [driverEfesSlice.reducerPath]: driverEfesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(employeeActiveSlice.middleware)
      .concat(postSlice.middleware)
      .concat(historyEfesSlice.middleware)
      .concat(briefingsEfesSlice.middleware)
      .concat(sizEfesSlice.middleware)
      .concat(firedEmployeeEfesSlice.middleware)
      .concat(normalSizEfesSlice.middleware)
      .concat(commissionEfesSlice.middleware)
      .concat(programBriefEfesSlice.middleware)
      .concat(allProgrammBriefSlice.middleware)
      .concat(trainingsEfesSlice.middleware)
      .concat(areaEfesSlice.middleware)
      .concat(driverEfesSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;