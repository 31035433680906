import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';


export interface Post {
  id: number;
  namepost: string;
}

export const postSlice = createApi({
  reducerPath: 'post',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPost: builder.query<Post[], void>({
      query: () => ({ url: '/efes/all_post', method: 'GET' }),
    }),
    getNonActivePosts: builder.query<Post[], void>({
      query: () => ({ url: '/efes/all_post_false', method: 'GET' }),
    }),
  }),
});

export const { useGetPostQuery, useGetNonActivePostsQuery } = postSlice;