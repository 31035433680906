import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export interface DriverEfes {
    employee: {
        id: number;
        post: {
            id: number;
            namepost: string;
        };
        fio: string;
        dateemployment: string | null;
    };
    dateinput: string | null;
    datepsih: string | null;
    number: string;
}

export const driverEfesSlice = createApi({
  reducerPath: 'driverEfes',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['DriverEfes'],
  endpoints: (builder) => ({
    getDriverEfes: builder.query<DriverEfes[], void>({
      query: () => ({ url: '/efes/all_drivers', method: 'GET' }),
      transformResponse: (response: DriverEfes[]) =>
        response.map((driver) => ({
          ...driver,
          dateinput: formatDate(driver.dateinput),
          datepsih: formatDate(driver.datepsih),
          employee: {
            ...driver.employee,
            dateemployment: formatDate(driver.employee.dateemployment),
          },
        })),
      providesTags: ['DriverEfes'],
    }),
  }),
});

export const { useGetDriverEfesQuery } = driverEfesSlice;