/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SchoolIcon from "@mui/icons-material/School";
import '../Styles/Navbar.scss';

import HomeIcon from "@mui/icons-material/Home";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import LogoutIcon from "@mui/icons-material/Logout";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from '@mui/icons-material/Settings';
import { useLocation } from "react-router-dom";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

const Navbar = () => {
    const navigate = useNavigate();
  
    const handleLogout = async () => {
      const confirmLogout = window.confirm("Точно хотите выйти?");
      if (!confirmLogout) return; 
  
      try {
        localStorage.removeItem("token_efes");
        navigate("/efes/login");
      } catch (error) {
        console.log("Не удалось выйти");
      }
    };

    const location = useLocation();
    const [isTargetPage, setIsTargetPage] = useState(false);

    useEffect(() => {
        if (location.pathname === "/efes/settings") {
          setIsTargetPage(true);
        } else {
          setIsTargetPage(false);
        }
      }, [location.pathname]);
  
    return (
      <div className="navbar_container">
        <div style={{ width: "78px" }}></div>
        <div className={isTargetPage ? "sidebar close setting_nav" : "sidebar close"}>
          <ul className="nav-links">
            <li>
              <a href="/efes/">
                <i>
                  <HomeIcon sx={{ fontSize: 25, color: "black" }} />
                </i>
              </a>
            </li>
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i>
                    <BackupTableIcon sx={{ fontSize: 25, color: "black" }} />
                  </i>
                </a>
              </div>
              <ul className="sub-menu">
                <li>
                  <a className="link_name">
                    Таблицы
                  </a>
                </li>
                <li>
                  <a href="/efes/employee">Сотрудники</a>
                </li>
                <li>
                  <a href="/efes/briefings">Инструктажи</a>
                </li>
                <li>
                  <a href="/efes/driver">Водит. удостоверения</a>
                </li>
                <li>
                  <a href="/efes/siz">СИЗ</a>
                </li>
                <li>
                  <a href="/efes/fired">Уволенные</a>
                </li>
              </ul>
            </li>
            <li>
                <a href="/efes/trainings">
                  <i>
                    <SchoolIcon sx={{ fontSize: 25, color: "black" }} />
                  </i>
                </a>
              </li>
            {localStorage.getItem("role_efes") !== "Бригадир" && (
              <li>
                <a href="/efes/history">
                  <i>
                    <HistoryIcon sx={{ fontSize: 25, color: "black" }} />
                  </i>
                </a>
              </li>
            )}
            {localStorage.getItem("role_efes") !== "Бригадир" && (
              <li>
                <a href="/efes/print">
                  <i>
                    <LocalPrintshopIcon sx={{ fontSize: 25, color: "black" }} />
                  </i>
                </a>
              </li>
            )}
            {localStorage.getItem("role_efes") !== "Бригадир" && (
              <li>
                <div className={isTargetPage ? "iocn-link setting_nav" : "iocn-link"}>
                  <a href="#">
                    <i>
                      <SettingsIcon sx={{ fontSize: 25, color: "black" }} />
                    </i>
                  </a>
                </div>
                <ul className="sub-menu">
                  <li>
                    <a className="link_name">
                      Настройки
                    </a>
                  </li>
                  <li>
                    <a href="/efes/settings">Основные</a>
                  </li>
                  <li>
                    <a href="/efes/normal_siz">Нормы СИЗ</a>
                  </li>
                </ul>
              </li>
            )}
            <li>
              <a onClick={handleLogout}>
                <i>
                  <LogoutIcon sx={{ fontSize: 25, color: "black" }} />
                </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };
  
  export default Navbar;
  