import React, {useState} from "react";
import Button from "@mui/material/Button";
import axiosInstance from '../axiosConfigEfes.js';
import Notification from "../Components/Notification.tsx";
import "../Styles/Print.scss";
import Select, { SingleValue } from "react-select";
import { Employee_active, useGetEmployeesQuery } from "../Global/EmployeeActiveSlice.ts";
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useGetPostQuery } from "../Global/PostEfesSlice.ts";

interface EmployeeOption {
    value: number;
    label: string;
}

const Print = () => {
    //Журнал регистрации инструктажей
    const [showModalPrintJournal, setShowModalPrintJournal] = useState(false);
    const [showModalPrintSiz, setShowModalPrintSiz] = useState(false);
    const [typeJoural, setTypeJournal] = useState("");
    const [reasonJournal, setReasonJournal] = useState("");
    const [fiomanagerJournal, setFiomanagerJournal] = useState("");
    const [postmanagerJournal, setPostmanagerJournal] = useState("");
    const [datestartJournal, setDatestartJournal] = useState(() => {
        const today = new Date();
        return today.toISOString().split("T")[0];
    });
    const [dateendJournal, setDateendJournal] = useState(() => {
        const today = new Date();
        return today.toISOString().split("T")[0];
    });
    const { data: employees_active } = useGetEmployeesQuery();
    const [selectedEmployee, setSelectedEmployee] = useState<SingleValue<EmployeeOption>>(null);
    const [typeSiz, setTypeSiz] = useState("");

    const employeeOptions: EmployeeOption[] = (employees_active ?? []).map((employee) => ({
        value: employee.id,
        label: employee.fio,
    }));

    const { data: postOptions, error, isLoading } = useGetPostQuery();

    // Состояние для хранения выбранных должностей
    const [selectedPosts, setSelectedPosts] = useState<number[]>([]);

    // Обработчик для изменения состояния
    const handleCheckboxChange = (postId: number) => {
        setSelectedPosts((prevSelected) =>
        prevSelected.includes(postId)
            ? prevSelected.filter((id) => id !== postId)
            : [...prevSelected, postId]
        );
    };

    

    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };

    const handlePrintJournal = async () => {

        if (!typeJoural || !reasonJournal || !fiomanagerJournal || !postmanagerJournal || !datestartJournal || !dateendJournal) {
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: "Необходимо заполнить все поля",
              subtitle2: <div></div>,
              isVisible: true,
            });
            return;
        }

        const formattedDateStart = new Date(datestartJournal).toISOString();
        const formattedDateEnd = new Date(dateendJournal).toISOString();
      
        const data1 = {
            type: typeJoural,
            reason: reasonJournal,
            fiomanager: fiomanagerJournal,
            postmanager: postmanagerJournal,
            datestart: formattedDateStart,
            dateend: formattedDateEnd,
            posts: selectedPosts,
        };
  
        try {
            setNotification({
                status: "loading",
                title: "Создание документа",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
            const response = await axiosInstance.post(`/efes/documents/journal_brief`, data1, {
                responseType: "blob",
            });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("target", "_blank");
            link.click();
            window.URL.revokeObjectURL(url);

            hideNotification();
            setShowModalPrintJournal(false);

        } catch (error) {
            setNotification({
                status: "error",
                title: "Произошла ошибка",
                subtitle: "Повторите еще раз или обратитесь к разработчику",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    const handlePrintSiz = async () => {

        if (!selectedEmployee || !typeSiz) {
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: "Необходимо указать все данные",
              subtitle2: <div></div>,
              isVisible: true,
            });
            return;
        }
      
        const data1 = {
            employeeid: selectedEmployee.value,
            type: typeSiz,
        };
  
        try {
            setNotification({
                status: "loading",
                title: "Создание документа",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
            const response = await axiosInstance.post(`/efes/documents/siz`, data1, {
                responseType: "blob",
            });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("target", "_blank");
            link.click();
            window.URL.revokeObjectURL(url);

            hideNotification();
            setShowModalPrintSiz(false);
            setSelectedEmployee(null);
            setTypeSiz("");

        } catch (error) {
            setNotification({
                status: "error",
                title: "Произошла ошибка",
                subtitle: "Повторите еще раз или обратитесь к разработчику",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    const handlePrintRaspStaz = async () => {

        if (!selectedEmployee || !typeSiz) {
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: "Необходимо указать все данные",
              subtitle2: <div></div>,
              isVisible: true,
            });
            return;
        }
      
        const data1 = {
            employeeid: selectedEmployee.value,
            type: typeSiz,
        };
  
        try {
            setNotification({
                status: "loading",
                title: "Создание документа",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
            const response = await axiosInstance.post(`/efes/documents/siz`, data1, {
                responseType: "blob",
            });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("target", "_blank");
            link.click();
            window.URL.revokeObjectURL(url);

            hideNotification();
            setShowModalPrintSiz(false);
            setSelectedEmployee(null);
            setTypeSiz("");

        } catch (error) {
            setNotification({
                status: "error",
                title: "Произошла ошибка",
                subtitle: "Повторите еще раз или обратитесь к разработчику",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    const season = [
        { value: "Зима", label: "Зима" },
        { value: "Лето", label: "Лето" },
    ];


    return (
        <div className="container_print_efes">
            <div style={{ fontWeight: "700", fontSize: "25px", color: "#2F3747" }}>Печать документов</div>

            {/* <div className="block_print_more_efes">
                <div style={{ fontWeight: "500", fontSize: "18px", color: "#2F3747", marginBottom: "5px"}}>Основные документы</div>
                 <Button
                    variant="contained"
                    onClick={() => setShowModalPrintSiz(true)}
                    style={{ marginTop: "10px" }}
                    >
                    СИЗ
                </Button> 
            </div> */}

            <div className="block_print_more_efes">
                <div style={{ fontWeight: "500", fontSize: "18px", color: "#2F3747", marginBottom: "5px"}}>Другие документы</div>
                <Button
                    variant="contained"
                    onClick={() => setShowModalPrintJournal(true)}
                    style={{ marginTop: "10px" }}
                    >
                    Журнал регистрации инструктажей на рабочем месте
                </Button>
            </div>

            {/* Модальное окно для сиз*/}
            {showModalPrintSiz && (
                <div className="modal_print_journal_efes">
                    <div className="modal_cont_print_journal_efes" style={{flexDirection: "column", height: "190px"}}>
                        <div className="title_employee_efes">Печать СИЗ</div>

                        <Select
                            id="employee-dropdown"
                            value={selectedEmployee}
                            options={employeeOptions}
                            onChange={(employeeOptions) => setSelectedEmployee(employeeOptions)}
                            placeholder="Выберите сотрудника"
                            styles={{
                                control: (provided) => ({ ...provided, marginBottom: "15px" }),
                            }}
                        />

                        <Select
                            id="siz-type"
                            placeholder="Выберите сезон"
                            value={season.find((opt) => opt.value === typeSiz)} 
                            onChange={(selectedOption) => setTypeSiz(selectedOption?.value || "")}
                            options={season}
                        />


                        <div className="modal_act_fir" style={{marginLeft: "auto", marginRight: "auto", marginTop: "10px"}}>
                            <button onClick={handlePrintSiz}>Печать</button>
                            <button onClick={() => setShowModalPrintSiz(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Модальное окно для добавления информации по журналу регистрации инструктажей*/}
            {showModalPrintJournal && (
                <div className="modal_print_journal_efes">
                    <div className="modal_cont_print_journal_efes">
                        <div className="title_employee_efes" style={{height: "70px"}}>Журнал регистрации инструктажей на рабочем месте</div>

                        <label
                            htmlFor="type-dropdown"
                        >
                            Вид инструктажа:
                        </label>
                        <select
                            id="type-dropdown"
                            value={typeJoural}
                            style={{ width: "174px", marginBottom: "10px", height: "22px"}}
                            onChange={(e) => setTypeJournal(e.target.value)}
                            >
                            <option value="" disabled>
                                Не выбран
                            </option>
                            <option value="Внеплановый" key="Внеплановый" >
                                Внеплановый
                            </option>
                        </select>

                        <label htmlFor="reasonJournal">Причина проведения инструктажа по ОТ (для внепланового или целевого)</label>
                        <input
                            type="text"
                            name="reasonJournal"
                            value={reasonJournal}
                            onChange={(e) => setReasonJournal(e.target.value)}
                            style={{width: "100%", marginTop: "5px", marginBottom: "10px"}}
                        ></input>

                        <label htmlFor="fiomanagerJournal">ФИО проводившего инструктаж</label>
                        <input
                            type="text"
                            name="fiomanagerJournal"
                            value={fiomanagerJournal}
                            onChange={(e) => setFiomanagerJournal(e.target.value)}
                            style={{width: "100%", marginTop: "5px", marginBottom: "10px"}}
                        ></input>

                        <label htmlFor="postmanagerJournal">Должность проводившего инструктаж</label>
                        <input
                            type="text"
                            name="postmanagerJournal"
                            value={postmanagerJournal}
                            onChange={(e) => setPostmanagerJournal(e.target.value)}
                            style={{width: "100%", marginTop: "5px", marginBottom: "15px"}}
                        ></input>

                        <label htmlFor="datestartJournal">Дата начала:</label>
                        <input
                            type="date"
                            id="datestartJournal"
                            name="datestartJournal"
                            value={datestartJournal}
                            onChange={(e) => setDatestartJournal(e.target.value)}
                            style={{ width: "213px"}}
                        />

                        <label htmlFor="dateendJournal" style={{ marginTop: "10px"}}>Дата окончания:</label>
                        <input
                            type="date"
                            id="dateendJournal"
                            name="dateendJournal"
                            value={dateendJournal}
                            onChange={(e) => setDateendJournal(e.target.value)}
                            style={{ width: "180px", marginTop: "10px"}}
                        />

                        <label style={{ marginTop: "15px", marginBottom: "10px"}}>Выберите должность/должности:</label>
                        <div id="scroll_post" style={{  height: "100px", overflowY: "scroll", marginBottom: "10px"}}>
                            {postOptions?.map((post) => (
                                <FormControlLabel
                                    key={post.id} 
                                    control={
                                        <Checkbox
                                            checked={selectedPosts.includes(post.id)}
                                            onChange={() => handleCheckboxChange(post.id)}
                                        />
                                    }
                                    label={post.namepost}
                                    style={{ width: "95%", height: "25px"}}
                                />
                            ))}
                        </div>

                        

                        <div className="modal_act_fir" style={{marginLeft: "auto", marginRight: "auto", marginTop: "10px"}}>
                            <button onClick={handlePrintJournal}>Печать</button>
                            <button onClick={() => setShowModalPrintJournal(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {notification.isVisible && (
                <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                onClose={hideNotification}
                isVisible={notification.isVisible}
                subtitle2={notification.subtitle2}
                />
            )}
        </div>
    );
}

export default Print;