import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export interface SizEfes {
    fio: string;
    height: string;
    clothingsize: string;
    shoesize: string;
    headdress: string;
    datesiz: string | null;
}

export const sizEfesSlice = createApi({
  reducerPath: 'sizEfes',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['SizEfes'],
  endpoints: (builder) => ({
    getSizEfes: builder.query<SizEfes[], void>({
      query: () => ({ url: '/efes/all_siz', method: 'GET' }),
      transformResponse: (response: SizEfes[]) =>
        response.map((history) => ({
          ...history,
          datesiz: formatDate(history.datesiz),
        })),
      providesTags: ['SizEfes'],
    }),
  }),
});

export const { useGetSizEfesQuery } = sizEfesSlice;