import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

export interface ProgramBrief {
    id: number;
    namebrief: string;
    bumbrief: string;
    dateyt: string | null;
    datepr: string | null;
}

export const allProgrammBriefSlice = createApi({
    reducerPath: 'allProgrammBrief',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['AllProgrammBrief'],
    endpoints: (builder) => ({
      getAllProgrammBrief: builder.query<ProgramBrief[], void>({
        query: () => ({ url: '/all_data/programms_brief', method: 'GET' }),
        transformResponse: (response: ProgramBrief[]) =>
          response.map((programBrief) => ({
            ...programBrief,
            dateyt: formatDate(programBrief.dateyt),
            datepr: formatDate(programBrief.datepr),
          })),
        providesTags: ['AllProgrammBrief'],
      }),
    }),
  });
  
  export const { useGetAllProgrammBriefQuery } = allProgrammBriefSlice;