import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export interface Employee_active {
  id: number;
  fio: string;
  datebirthday: string | null;
  phone: string;
  gender: string;
  dateemployment: string | null;
  // shift: {
  //   id: number;
  //   nameshift: string;
  // };
  post: {
    id: number;
    namepost: string;
  };
}

export interface EmployeeActiveState {
  selectedEfesEmployee: Employee_active | null;
}

const employeeEfesSlice = createSlice({
  name: 'employeeEfesState',
  initialState: {
    selectedEfesEmployee: null,
  } as EmployeeActiveState,
  reducers: {
    setSelectedEfesEmployee(state, action: PayloadAction<Employee_active | null>) {
      state.selectedEfesEmployee = action.payload;
    },
  },
});

export const employeeActiveSlice = createApi({
  reducerPath: 'employeeActive',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ActiveEmployeesEfes'],
  endpoints: (builder) => ({
    getEmployees: builder.query<Employee_active[], void>({
      query: () => ({ url: '/efes/active_employee', method: 'GET' }),
      transformResponse: (response: Employee_active[]) =>
        response.map((employee) => ({
          ...employee,
          datebirthday: formatDate(employee.datebirthday),
          dateemployment: formatDate(employee.dateemployment),
        })),
      providesTags: ['ActiveEmployeesEfes'],
    }),
  }),
});

export const { useGetEmployeesQuery } = employeeActiveSlice;
export const { setSelectedEfesEmployee } = employeeEfesSlice.actions;

export const employeeEfesReducer = employeeEfesSlice.reducer;
export const employeeApiReducer = employeeActiveSlice.reducer;
