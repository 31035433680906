import React, { useState, useEffect, useCallback } from "react";
import InputMask from "react-input-mask";
import "../Styles/Employee.scss";
import axiosInstance from "../axiosConfigEfes.js";
import Notification from "../Components/Notification.tsx";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetPostQuery } from "../Global/PostEfesSlice.ts";
import { useGetEmployeesQuery } from "../Global/EmployeeActiveSlice.ts";
import CircularProgress from '@mui/material/CircularProgress';

interface EmployeeAdd {
    fio: string;
    gender: string;
    datebirthday: string;
    phone: string;
    postid: string;
    typeemployment: string;
    dateemployment: string;
    numelectrosecurity: number;
    dateelectro: string;
    datefire: string;
    height: string;
    clothingsize: string;
    shoesize: string;
    headdress: string;
    datesiz: string;
  }

const ModalEdit = ({ selectedEmployeeId, closeModalUpd, onUpdateSuccess }) => {
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isLoadingUpd, setIsLoadingUpd] = useState(false);
    const { refetch } = useGetEmployeesQuery();
    // поля модального окна
    const [fio, setFio] = useState("");
    const [gender, setGender] = useState("");
    const [datebirthday, setDateBirthday] = useState("");
    const [phone, setPhone] = useState("");
    //const [shift, setShift] = useState("");
    const [post, setPost] = useState("");
    const [dateemployment, setDateEmployment] = useState("");
    const [electro, setElectro] = useState("");
    const [dateelectro, setDateElectro] = useState("");
    const [datefire, setDateFire] = useState("");
    const [typeEmployment, setTypeEmployment] = useState("");
    const [height, setHeight] = useState("");
    const [clothingsize, setClothingSize] = useState("");
    const [shoesize, setShoeSize] = useState("");
    const [headdress, setHeaddress] = useState("");
    const [datesiz, setDateSiz] = useState("");

    const [initialEmpData, setInitialEmpData] = useState<EmployeeAdd | null>(null);

    // Данные по должностям и сменам
    //const [shiftOptions, setShiftOptions] = useState([]);
    const { data: postOptions } = useGetPostQuery();

    // Настройки всплывающего окна
    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        isVisible: false,
        subtitle2: <div></div>
    });

    const hideNotification = () => {
      setNotification({
        ...notification,
        isVisible: false,
      });
      closeModalUpd();
      onUpdateSuccess();
    };

    const [isDateModalVisible, setIsDateModalVisible] = useState(false);
    const [changedFields, setChangedFields] = useState({});

    // Данные по СИЗ
    const heightOptions = ["158-164", "170-176", "176-180", "182-186"];
    const shoesizeOptions = [
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
    ];
    const clothingsizeOptions = [
        "42-44",
        "44-46",
        "46-48",
        "48-50",
        "50-52",
        "52-54",
        "54-56",
        "58-60",
    ];
    const headdressOptions = ["44", "46", "48", "50", "52", "54", "56", "58"];
    

    // Проверка, что все поля заполнены
    const isSaveDisabled = !(
      fio &&
      gender &&
      datebirthday &&
      phone &&
      post &&
      dateemployment &&
      height &&
      clothingsize &&
      shoesize &&
      headdress &&
      electro &&
      dateelectro &&
      datefire &&
      datesiz
    );

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        axiosInstance
          .get(`/efes/get_employee/${selectedEmployeeId}`)
          .then((response) => {
            const employee = response.data;
            setInitialEmpData(employee);
            setFio(employee.fio);
            setGender(employee.gender);
            setDateBirthday(formatDate(employee.datebirthday));
            setPhone(employee.phone);

            const isValidPost = postOptions?.some((option: any) => option.id === employee.postid);
            if (!isValidPost) {
              setPost("");
            } else{
              setPost(employee.postid);
            }
            setTypeEmployment(employee.typeemployment);
            setDateEmployment(formatDate(employee.dateemployment));

            setElectro(employee.numelectrosecurity);
            setDateElectro(formatDate(employee.dateelectro));
            setDateFire(formatDate(employee.datefire));
            
            setHeight(employee.height);
            setClothingSize(employee.clothingsize);
            setShoeSize(employee.shoesize);
            setHeaddress(employee.headdress);
            setDateSiz(formatDate(employee.datesiz));
          })
          .catch((error) => {
            console.error(
              "Ошибка при получении данных о сотруднике для редактирования:",
              error
            );
          })
          .finally(() => {
            setIsLoadingData(false);
        });
    }, [selectedEmployeeId]);

    const saveUpd = async () => {
        const formattedDateBirthday = new Date(datebirthday).toISOString();
        const formattedDateEmpl = new Date(dateemployment).toISOString();
        const formattedDateElectro = new Date(dateelectro).toISOString();
        const formattedDateFire = new Date(datefire).toISOString();
        const formattedDateSiz = new Date(datesiz).toISOString();

        const empData = {
            fio: fio,
            gender: gender,
            datebirthday: formattedDateBirthday,
            phone: phone,
            //shiftid: shift,
            postid: post,
            dateemployment: formattedDateEmpl,
            numelectrosecurity: electro,
            typeemployment: typeEmployment,
            dateelectro: formattedDateElectro,
            datefire: formattedDateFire,
            height: height,
            clothingsize: clothingsize,
            shoesize: shoesize,
            headdress: headdress,
            datesiz: formattedDateSiz,
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };
        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
            await axiosInstance.put(`/efes/edit_employee/${selectedEmployeeId}`, empData);
    
            setNotification({
                status: "success",
                title: "Успешно",
                subtitle: "Информация о сотруднике обновлена",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
        } catch (error) {
          console.error("Ошибка при обновлении данных SIZ:", error);
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Произошла ошибка",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      };    


    if (isLoadingData) {
        return (
            <div className="modal_add_employee_efes">
                <div className="modal_content_add_employee_efes">
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "750px", height: "482px"}}>
                        <div style={{marginBottom: "20px"}}>Загрузка данных о сотруднике, подождите</div>
                        <CircularProgress />
                    </div>
                </div>
            </div>
        );
    }

    return (
      <div>
        <div className="modal_add_employee_efes">
          <div className="modal_content_add_employee_efes">
            <span className="close" onClick={closeModalUpd}>
              &times;
            </span>
            <div className="title_employee_efes">Изменение данных о сотруднике</div>
            <div className="pers_info_employee_efes1">
              <h3>Личная информация</h3>

              <label htmlFor="fio">ФИО</label>
              <input
                type="text"
                name="fio"
                value={fio}
                onChange={(e) => setFio(e.target.value)}
                style={{width: "234px"}}
              ></input>

              <div className="gen">
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="М"
                  checked={gender === "М"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="male">Мужской</label>
                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="Ж"
                  checked={gender === "Ж"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="female">Женский</label>
              </div>

              <label htmlFor="datebirthday">Дата рождения:</label>
              <input
                type="date"
                id="datebirthday"
                name="datebirthday"
                value={datebirthday}
                onChange={(e) => setDateBirthday(e.target.value)}
                style={{width: "144px"}}
              />

              <br></br>

              <label htmlFor="phone" style={{ marginBottom: "10px" }}>
                Номер телефона:
              </label>
              <InputMask
                mask="+7 999 999 99-99"
                maskChar="_"
                placeholder="+7 ___ ___ __-__"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                style={{ width: "130px", marginBottom: "10px" }}
              />

              {/* <label htmlFor="shift-dropdown" style={{ marginBottom: "10px" }}>
                Смена:
              </label>
              <select
                id="shift-dropdown"
                value={shift}
                style={{ width: "225px", marginBottom: "10px" }}
                onChange={(e) => setShift(e.target.value)}
              >
                <option value="" disabled>
                  Выберите смену
                </option>
                {shiftOptions.map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.nameshift}
                  </option>
                ))}
              </select> */}

              <label htmlFor="post-dropdown" style={{ marginBottom: "10px" }}>
                Должность:
              </label>
              <select
                id="post-dropdown"
                value={post}
                style={{ width: "186px", marginBottom: "10px", height: "22px"}}
                onChange={(e) => setPost(e.target.value)}
              >
                <option value="" disabled>
                  Выберите должность
                </option>
                {postOptions?.map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.namepost}
                  </option>
                ))}
              </select>

              {localStorage.getItem("role_efes") !== "Бригадир" && (
                <div>
                  <label
                    htmlFor="typeEmployment-dropdown"
                  >
                    Тип трудоустройства:
                  </label>
                  <select
                    id="typeEmployment-dropdown"
                    value={typeEmployment}
                    style={{ width: "299px", marginBottom: "10px", marginTop: "10px", height: "22px"}}
                    onChange={(e) => setTypeEmployment(e.target.value)}
                  >
                    <option value="" disabled>
                      Выберите тип трудоустройства
                    </option>
                    <option value="ТД" key="ТД" >
                      ТД
                    </option>
                    <option value="СЗ" key="СЗ">
                      СЗ
                    </option>
                    <option value="Другое" key="Другое">
                      Другое
                    </option>
                  </select>
                </div>
              )}

              <label htmlFor="dateemployment">Дата трудоустройства:</label>
              <input
                type="date"
                id="dateemployment"
                name="dateemployment"
                value={dateemployment}
                onChange={(e) => setDateEmployment(e.target.value)}
                style={{ width: "294px"}}
              />
            </div>
            <div className="pers_info_employee_efes2" style={{marginLeft: "50px"}}>
              <h3>Инструктажи</h3>

              <label
                htmlFor="electro-dropdown"
              >
                Группа электробезопасности:
              </label>
              <select
                id="electro-dropdown"
                value={electro}
                style={{ width: "100%", marginBottom: "10px", marginTop: "10px", height: "22px"}}
                onChange={(e) => setElectro(e.target.value)}
              >
                <option value="" disabled>
                  Выберите группу электробезопасности
                </option>
                <option value="1" key="1" >
                  Первая
                </option>
                <option value="2" key="2">
                  Вторая
                </option>
                <option value="3" key="3">
                  Третья
                </option>
                <option value="4" key="4">
                  Четвертая
                </option>
              </select>

              <label htmlFor="dateelectro">Дата электробезопасности:</label>
              <input
                type="date"
                id="dateelectro"
                name="dateelectro"
                value={dateelectro}
                style={{ width: "146px"}}
                onChange={(e) => setDateElectro(e.target.value)}
              />

              <label htmlFor="datefire">Дата пожаробезопасности:</label>
              <input
                type="date"
                id="datefire"
                name="datefire"
                value={datefire}
                style={{ width: "149px", marginTop: "10px"}}
                onChange={(e) => setDateFire(e.target.value)}
              />

              <h3 style={{marginTop: "30px"}}>СИЗ</h3>

              <label htmlFor="height-dropdown" style={{ marginBottom: "10px" }}>
                Рост:
              </label>
              <select
                id="height-dropdown"
                value={height}
                style={{ width: "342px", marginBottom: "10px", height: "22px" }}
                onChange={(e) => setHeight(e.target.value)}
              >
                <option value="" disabled>
                  Выберите рост
                </option>
                {heightOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <br></br>

              <label
                htmlFor="clothingsize-dropdown"
                style={{ marginBottom: "10px" }}
              >
                Размер одежды:
              </label>
              <select
                id="clothingsize-dropdown"
                value={clothingsize}
                style={{ width: "245px", marginBottom: "10px", height: "22px" }}
                onChange={(e) => setClothingSize(e.target.value)}
              >
                <option value="" disabled>
                  Выберите размер одежды
                </option>
                {clothingsizeOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <label htmlFor="size-dropdown" style={{ marginBottom: "10px" }}>
                Размер обуви:
              </label>
              <select
                id="size-dropdown"
                value={shoesize}
                style={{ width: "262px", marginBottom: "10px", height: "22px" }}
                onChange={(e) => setShoeSize(e.target.value)}
              >
                <option value="" disabled>
                  Выберите размер обуви
                </option>
                {shoesizeOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <label
                htmlFor="headdress-dropdown"
                style={{ marginBottom: "10px" }}
              >
                Размер головного убора:
              </label>
              <select
                id="headdress-dropdown"
                value={headdress}
                style={{ width: "172px", marginBottom: "10px", height: "22px" }}
                onChange={(e) => setHeaddress(e.target.value)}
              >
                <option value="" disabled>
                  Выберите размер головного убора
                </option>
                {headdressOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <label htmlFor="datesiz">Дата выдачи СИЗ:</label>
              <input
                type="date"
                id="datesiz"
                name="datesiz"
                value={datesiz}
                onChange={(e) => setDateSiz(e.target.value)}
                style={{ width: "228px"}}
              />

              <button
                className="add_send_emploee"
                onClick={saveUpd}
                disabled={isSaveDisabled}
                style={{marginLeft: "248px", marginRight: "0px"}}
              >
                Сохранить
              </button>
            </div>
            {notification.isVisible && (
              <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                subtitle2={notification.subtitle2}
                onClose={hideNotification}
                isVisible={notification.isVisible}
              />
            )}
          </div>
        </div>
      </div>
    );
};

export default ModalEdit;