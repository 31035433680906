import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './efesBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export interface FiredEmployeeEfes {
    fio: string;
    comment: string;
    datefired: string | null;
    dateemployment: string | null;
}

export const firedEmployeeEfesSlice = createApi({
  reducerPath: 'firedEmployeeEfes',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['FiredEmployeeEfes'],
  endpoints: (builder) => ({
    getFiredEmployeeEfes: builder.query<FiredEmployeeEfes[], void>({
      query: () => ({ url: '/efes/all_fired_employee', method: 'GET' }),
      transformResponse: (response: FiredEmployeeEfes[]) =>
        response.map((history) => ({
          ...history,
          datefired: formatDate(history.datefired),
          dateemployment: formatDate(history.dateemployment),
        })),
      providesTags: ['FiredEmployeeEfes'],
    }),
  }),
});

export const { useGetFiredEmployeeEfesQuery } = firedEmployeeEfesSlice;