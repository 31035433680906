import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import IconButton from "@mui/material/IconButton";
import Notification from "../Components/Notification.tsx";
import axiosInstance from '../axiosConfigEfes.js';
import DeleteIcon from "@mui/icons-material/Delete";
import { useGetProgramBriefEfesQuery } from "../Global/ProgramBriefEfesSlice.ts";
import { useGetAllProgrammBriefQuery } from "../Global/AllProgrammBrief.ts";
import Select from "react-select";
import { useGetPostQuery } from "../Global/PostEfesSlice.ts";
import CircularProgress from '@mui/material/CircularProgress';

interface ProgramBrief {
    value: number; 
    label: string;
}

const SettingProgramBrief = () => {

    const { data: programBrief, error: programBriefError, isLoading: isLoadingProgramBrief, refetch: refetchProgramBrief } = useGetProgramBriefEfesQuery();
    const { data: allProgramm } = useGetAllProgrammBriefQuery();
    const { data: postOptions } = useGetPostQuery();

    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
          ...notification,
          isVisible: false,
        });
    };



    // Данные для программы
    const [showModalAddProgramBrief, setShowModalAddProgramBrief] = useState(false);

    const [nameProg, setNameProg] = useState("");
    const [numprog, setNumprog] = useState("");
    const [dateyt, setDateyt] = useState(() => {
        const today = new Date();
        return today.toISOString().split("T")[0];
    });
    const [datepe, setDatepe] = useState(() => {
        const today = new Date();
        return today.toISOString().split("T")[0];
    });
    const [postProg, setPostProg] = useState("");
    const [selectedMainBrief, setSelectedMainBrief] = useState<string | null>(null);
    const mainBriefOptions: ProgramBrief[] = [
        { value: 0, label: 'Не выбрано' },
        ...(allProgramm?.map((brief) => ({
            value: brief.id,
            label: brief.namebrief,
        })) || [])
    ];    

    const columnsProgBrief: GridColDef[] = [
        {
          field: "nameprog",
          headerName: "Наименование",
          width: 280,
          type: "string",
          editable: false,
          valueGetter: (value, row) => {
            if (row.nameprog != null){
              return row.nameprog;
            } else {
              return "Нет данных";
            }
          },
        },
        {
            field: "numprog",
            headerName: "Номер программы",
            width: 180,
            type: "string",
            editable: false,
            valueGetter: (value, row) => {
              if (row.numprog != null){
                return row.numprog;
              } else {
                return "Нет данных";
              }
            },
        },
        {
            field: "dateyt",
            headerName: "Дата утвержд.",
            width: 120,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
              if (!row.dateyt) {
                return null;
              }
              const [day, month, year] = row.dateyt.split(".");
              return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "datepe",
            headerName: "Дата пересмотра",
            width: 120,
            editable: false,
            type: "date",
            valueGetter: (value, row) => {
              if (!row.datepe) {
                return null;
              }
              const [day, month, year] = row.datepe.split(".");
              return new Date(`${year}-${month}-${day}`);
            },
        },
        {
            field: "post",
            headerName: "Должность",
            width: 180,
            type: "string",
            editable: false,
            valueGetter: (value, row) => {
              if (row.post != null){
                return row.post.namepost;
              } else {
                return "Нет данных";
              }
            },
        },
        {
            field: "button",
            headerName: "",
            width: 30,
            renderCell: (params) => (
                <div>
                    <IconButton onClick={() => confirmDeleteProgramBrief(params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
        }
    ]

    // Добавление программы инструктажей
    const confirmAddProgramBrief = async () => {
        if (!nameProg || !numprog || !dateyt || !datepe || !postProg) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Необходимо заполнить все поля",
            subtitle2: <div></div>,
            isVisible: true,
          });
          return;
        }

        const formattedDate1 = new Date(dateyt).toISOString();
        const formattedDate2 = new Date(datepe).toISOString();
    
        const data = {
            nameprog: nameProg,
            numprog: numprog,
            dateyt: formattedDate1,
            datepe: formattedDate2,
            postid: postProg,
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };

        try {
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });

            await axiosInstance.post(`/efes/add_new_program_brief`, data);
            
            setShowModalAddProgramBrief(false);
            refetchProgramBrief();
      
            setNotification({
              status: "success",
              title: "Успешно",
              subtitle: "Программа добавлена",
              subtitle2: <div></div>,
              isVisible: true,
            });
      
        } catch (error: any) {
            const errorMessage =
              error.response?.data || "Ошибка при добавлении программы"; 
        
            setNotification({
              status: "error",
              title: "Ошибка",
              subtitle: errorMessage,
              subtitle2: <div></div>,
              isVisible: true,
            });
          }
    }

    // Удаление программы
    const confirmDeleteProgramBrief = async (id) => {
        const confirmLogout = window.confirm("Точно хотите удалить программу?");
        if (!confirmLogout) return;

        const data1 = {
            fio_akk: localStorage.getItem("fio_efes"),
            role_akk: localStorage.getItem("role_efes"),
        };
    
        try {
    
            setNotification({
                status: "loading",
                title: "Обновление данных",
                subtitle: "Пожалуйста, подождите...",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
            await axiosInstance.delete(`/efes/delete_program_brief/${id}`, { data: data1 });

            refetchProgramBrief();
    
            setNotification({
                status: "success",
                title: "Успешно",
                subtitle: "Программа удалена",
                subtitle2: <div></div>,
                isVisible: true,
            });
    
        } catch (error) {
            setNotification({
                status: "error",
                title: "Ошибка",
                subtitle: "Ошибка при удалении программы",
                subtitle2: <div></div>,
                isVisible: true,
            });
        }
    };

    const formatDate2 = (dateString: string | null) => {
        if (!dateString) return '';
        const [day, month, year] = dateString.split('.');
        return `${year}-${month}-${day}`;
    };
    

    const handleProgramSelect = (selectedOption: any) => {
        
        const selectedProgram = allProgramm?.find((program) => program.id === selectedOption?.value);
        if (selectedProgram && selectedProgram.id !== 0) {
            setSelectedMainBrief(selectedProgram.id.toString());
            setNameProg(selectedProgram.namebrief);
            setNumprog(selectedProgram.bumbrief);
            setDateyt(formatDate2(selectedProgram.dateyt));
            setDatepe(formatDate2(selectedProgram.datepr));
        } else {
            setSelectedMainBrief(null);
            setNameProg('');
            setNumprog('');
            setDateyt('');
            setDatepe('');
            setPostProg('');
        }
    };

    if (isLoadingProgramBrief)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "calc(100vh - 60px)"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
    
    if (programBriefError) 
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "calc(100vh - 60px)"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
                <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
                <img src={require("../Assets/error.svg").default} alt="" width={"80px"} />
            </div>
        </div>
    ;


    return (
        <div>
            <div className="homepage_dashboard_container" style={{ width: "fit-content" }}>
                <div
                    className="block_employee_num_settings_efes"
                    style={{ marginTop: "15px", width: "940px" }}
                    >
                    <div style={{ fontWeight: "700", fontSize: "18px", color: "#2F3747", textAlign: "center" }}>Программы инструктажей</div>
                    <Button
                        variant="contained"
                        onClick={() => setShowModalAddProgramBrief(true)}
                        style={{ marginTop: "10px", width: "100%", fontWeight: "600", fontSize: "12px" }}
                        >
                        Добавить программу
                    </Button>
                    <div className="table_settings_container_efes">
                        <DataGrid
                            style={{
                                marginTop: 20,
                                height: 319,
                            }}
                            rows={programBrief ?? []}
                            columns={columnsProgBrief}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 4,
                                },
                            },
                            }}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            pageSizeOptions={[4]}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            slotProps={{ toolbar: { showQuickFilter: true } }}
                        />
                    </div>
                </div>
            </div>

            {/* Модальное окно для добавления программы */}
            {showModalAddProgramBrief && (
                <div className="modal_add_program_brief_employee_efes">
                    <div className="modal_cont_add_program_brief_employee_efes">
                        <div className="title_employee_efes">Добавление новой программы</div>

                        <div style={{ fontSize: "13px", color: "#4c4c4c"}}>Вы можете выбрать уже готовую программу из списка или создать новую, заполнив все поля</div>

                        <div className="tr_cont_select">
                            <Select
                                id="employee-dropdown"
                                value={mainBriefOptions.find((option) => option.value.toString() === selectedMainBrief) || null}
                                options={mainBriefOptions}
                                onChange={handleProgramSelect}
                                placeholder="Программа не выбрана"
                                styles={{
                                    control: (base) => ({
                                    ...base,
                                    minWidth: '200px',
                                    margin: '10px 0',
                                    }),
                                }}
                            />
                        </div>

                        <label htmlFor="nameProg">Наименование</label>
                        <textarea
                            name="nameProg"
                            placeholder=""
                            value={nameProg}
                            onChange={(e) => setNameProg(e.target.value)}
                            rows={4}
                        />

                        <div>
                            <label htmlFor="numprog">Номер программы</label>
                            <input
                                type="text"
                                name="numprog"
                                value={numprog}
                                onChange={(e) => setNumprog(e.target.value)}
                                style={{width: "156px", marginBottom: "10px"}}
                            ></input>
                        </div>

                        <div>
                            <label htmlFor="dateyt">Дата утверждения:</label>
                            <input
                                type="date"
                                id="dateyt"
                                name="dateyt"
                                value={dateyt}
                                onChange={(e) => setDateyt(e.target.value)}
                                style={{width: "158px", marginBottom: "10px"}}
                            />
                        </div>

                        <div>
                            <label htmlFor="datepe">Дата пересмотра:</label>
                            <input
                                type="date"
                                id="datepe"
                                name="datepe"
                                value={datepe}
                                onChange={(e) => setDatepe(e.target.value)}
                                style={{width: "158px", marginBottom: "10px", marginLeft: "12px"}}
                            />
                        </div>

                        <div>
                            <label htmlFor="post-dropdown" style={{ marginBottom: "10px" }}>
                                Должность:
                            </label>
                            <select
                                id="post-dropdown"
                                value={postProg}
                                style={{ width: "227px", marginBottom: "15px", height: "22px"}}
                                onChange={(e) => setPostProg(e.target.value)}
                            >
                                <option value="" disabled>
                                Выберите должность
                                </option>
                                {postOptions?.map((option: any) => (
                                <option key={option.id} value={option.id}>
                                    {option.namepost}
                                </option>
                                ))}
                            </select>
                        </div>

                        <div className="modal_act_fir" style={{ marginLeft: "auto", marginRight: "auto"}}>
                            <button onClick={confirmAddProgramBrief}>Добавить</button>
                            <button onClick={() => setShowModalAddProgramBrief(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}

            {notification.isVisible && (
                <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                onClose={hideNotification}
                isVisible={notification.isVisible}
                subtitle2={notification.subtitle2}
                />
            )}

        </div>
    );
}

export default SettingProgramBrief;